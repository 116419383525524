import React from 'react'
import styles from "./OrderProduct.module.scss"
import { Button, Modal } from '@mui/material'
// create a react portal to render order product

export default function OrderProduct({ handleClose }) {

    return (
        <Modal open={true} style={{ backdropFilter: "blur(14px)", background: "transparent", backgroundColor: "rgba(0, 0, 0, 0.09)", display: "grid", placeItems: "center" }}>
            <div id={styles.order}>
                <div>
                    <h3>PRODUCT DESCRIPTION</h3>
                    <div className="flex">
                        <h6>Custom Standard Hoodie (Size: M)</h6>
                        <h6>$***</h6>
                    </div>
                    <div className="flex">
                        <h6>Delivery</h6>
                        <h6>$***</h6>
                    </div>
                    <hr />
                    <div className="flex">
                        <h6>Total</h6>
                        <h6>$***</h6>
                    </div>
                </div>

                <div>
                    <div className="flex">
                        <h3>Linked Gaming accounts</h3>
                    </div>
                    <div className="img-group">
                        <img src="/images/Xbox_one_logo.svg.png" alt="" />
                        <img src="/images/1280px-PlayStation_logo.svg.png" alt="" />
                        <img src="/images/Steam_icon_logo.svg.png" alt="" />
                        <img src="/images/Unreal+Engine_eg-logo-filled-1255x1272-0eb9d144a0f981d1cbaaa1eb957de7a3207b31bb.png" alt="" />
                    </div>
                </div>

                <div>
                    <h3>Linked Gaming accounts</h3>
                    <h6>6 Branch Road<br />
                        Richem,<br />
                        Portugal
                    </h6>
                    <h6>097 897 8766</h6>
                </div>

                <div>
                    <h3>Deliver by</h3>
                    <h6>Monday 6 November 2023</h6>
                    <h6>Standard Delivery</h6>
                </div>

                <div>
                    <h3>Payment Method</h3>
                    <h6>Credit & Debit Card</h6>
                    <div className="img-group">
                        <img src="/images/Visa-Logo-2006.png" alt="" />
                        <img src="/images/Mastercard-logo.svg.png" alt="" />
                        <img src="/images/American-Express-Color.png" alt="" />
                    </div>
                </div>

                <div className="flex">
                    <Button about="light" onClick={handleClose}>Cancel</Button>
                    <Button about="buy">Buy Now</Button>
                </div>
            </div>
        </Modal >
    )
}
