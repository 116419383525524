import React from 'react'
import styles from "./loginOrSignUpPop.module.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { appRoutes } from '../../../../../../utils/appRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { setLinkToRedirectTo, setLoginAndSignPopup, setUserType } from '../../../../../../redux/mainPageSlice'
import { ENDUSER } from '../../../../../../utils/constants'

export default function LoginOrSignUpPopUp() {
    const loginAndSignPopup = useSelector(state => state.mainPageSliceReducer.loginAndSignPopup)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(setLoginAndSignPopup(null))
    }

    const navigate = useNavigate()

    const location = useLocation()

    const handleLogin = () => {
        dispatch(setUserType(ENDUSER))
        dispatch(setLinkToRedirectTo(location.pathname))
        dispatch(setLoginAndSignPopup(null))
        navigate(appRoutes.login)
    }

    const handleSignup = () => {
        dispatch(setUserType(ENDUSER))
        dispatch(setLinkToRedirectTo(location.pathname))
        dispatch(setLoginAndSignPopup(null))
        navigate(appRoutes.signup)
    }
    return (
        <>
            {loginAndSignPopup && <div id={styles.loginOrSignup} >
                <div className="popup-card" style={{
                    backgroundImage: `url("/images/rays.svg")`,
                }}>
                    <div className="d-flex text-container">
                        <div className="popup-text">
                            {loginAndSignPopup?.message}
                        </div>
                        <div className="close-icon" onClick={handleClose}>
                            <img src="/images/closeSignupPopUp.svg" alt="" />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="login-account">
                            <div className="login-text">Do you have an account?</div>
                            <div className="login-btn" role="button" onClick={handleLogin}>
                                <div className="d-flex">
                                    <img src="/images/keyIcon.svg" alt="" />
                                    <div className="login-btn-text">Log In Your Account</div>
                                </div>
                            </div>
                            <div className="login-btn">
                                <div className="d-flex">
                                    <img src="/images/googlesignup.png" alt="" />
                                    <div className="login-btn-text">Log In In Google</div>
                                </div>
                            </div>
                        </div>
                        <div className="login-account signup-color">
                            <div className="login-text">Don't have an account?</div>
                            <div className="login-btn " role="button" onClick={handleSignup}>
                                <div className="d-flex">
                                    <img src="/images/addUser.svg" alt="" />
                                    <div className="login-btn-text">Create An Account</div>
                                </div>
                            </div>
                            <div className="login-btn">
                                <div className="d-flex">
                                    <img src="/images/googlesignup.png" alt="" />
                                    <div className="login-btn-text">Sign Up With Google</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
