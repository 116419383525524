import * as THREE from 'three';
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js';
export const outlinePass=(width,height,scene,camera)=>{
    const outlinePass = new OutlinePass(new THREE.Vector2(width, height), scene, camera);
    outlinePass.visibleEdgeColor.set('#C4FF03');
    outlinePass.hiddenEdgeColor.set('#C4FF03');
    outlinePass.edgeThickness = 9;
    outlinePass.edgeStrength= 10;
    outlinePass.edgeGlow= 0.0;
    outlinePass.edgeThickness= 1.0;
    outlinePass.pulsePeriod=2.5;
    // outlinePass.selectedObjects = [selectedObject];
    return outlinePass
}