import { useEffect, useState } from "react";

export const useFullScreen = (webGLContainerRef) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const enterFullscreen = () => {
    if (webGLContainerRef.current.requestFullscreen) {
      webGLContainerRef.current.requestFullscreen();
    } else if (webGLContainerRef.current.webkitRequestFullscreen) {
      // Safari
      webGLContainerRef.current.webkitRequestFullscreen();
    } else if (webGLContainerRef.current.mozRequestFullScreen) {
      // Firefox
      webGLContainerRef.current.mozRequestFullScreen();
    } else if (webGLContainerRef.current.msRequestFullscreen) {
      // IE/Edge
      webGLContainerRef.current.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      // Safari
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(
      !!(
        document.fullscreenElement === webGLContainerRef.current ||
        document.webkitFullscreenElement === webGLContainerRef.current ||
        document.mozFullScreenElement === webGLContainerRef.current ||
        document.msFullscreenElement === webGLContainerRef.current
      )
    );
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  return {toggleFullscreen}
};