import { gql } from "@apollo/client";

export const INSERT_GAME = gql`
  mutation INSERT_GAME(
    $environment: String!
    $name: String!
    $notes: String!
    $website_url: String!
  ) {
    insert_sdk_games_one(
      object: {
        environment: $environment
        name: $name
        notes: $notes
        website_url: $website_url
      }
    ) {
      id
    }
  }
`;
