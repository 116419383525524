import React from 'react'
import Steps from '../../Steps'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../utils/appRoutes'
import { apiRoutes, authetictedAxios } from '../../../../utils/api'
import { Alert, Backdrop, CircularProgress, Grid, LinearProgress } from '@mui/material'
import { SingleMerchizeItem } from '../../../../components/MerchizeProduct/SingleMerchizeItem'
import { useQuery } from 'react-query'
import { useQuery as useApolloClient } from '@apollo/client'
import { UPDATE_SELECTED_PRODUCT } from '../../../../graphql/updateLinkedProduct.mutation'
import { useMutation } from '@apollo/client'
import RightPageDetailsNoLayout from '../../../../layout/RightPageDetailsNoLayout.layout'
import { DO_WE_HAVE_LINKED_PRODUCT } from '../../../../graphql/doWeHaveLinkedProduct.query'

export default function SelectProduct() {

  // handle back to glb upload
  const { id } = useParams()
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(appRoutes.uploadGlBFunc(id))
  }

  //handle list of products api
  const { isLoading, data, isError } = useQuery(apiRoutes.merchizeProducts, async () => {
    const res = await authetictedAxios().get(apiRoutes.merchizeProducts)
    if (res) {
      return res.data
    }
  }, {
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const [updateSelectedProduct, { loading, error }] = useMutation(UPDATE_SELECTED_PRODUCT)
  const { loading: loadingCurrent, data: currentData, error: currentError } = useApolloClient(DO_WE_HAVE_LINKED_PRODUCT, {
    variables: {
      id
    }
  })
  const handleNext = () => {
    navigate(appRoutes.uploadGlb + appRoutes.uploadGlbSubRoutes.matchGlbFunc(id))
  }

  const handleClick = async (id, product_id) => {
    const res = await updateSelectedProduct({
      variables: {
        id,
        linked_merchize_product: product_id
      }
    })
    if (res.data.update_users_glbs_by_pk?.linked_merchize_product) {
      handleNext()
    }
  }
  return (
    <>
      <div style={{ width: "100%" }}><Steps activeStep={1} handleBack={handleBack} handleNext={() => { }} /></div>
      <RightPageDetailsNoLayout title="Choose Merchize Product" subTitle="Choose a product related to the glb">
        {(isLoading || loadingCurrent) && <LinearProgress color="info"></LinearProgress>}
        {currentError && <Alert severity='error' sx={{ marginBottom: "20px" }}>There was an error loading recent error</Alert>}
        {error && <Alert severity='error' sx={{ marginBottom: "20px" }}>Error Linking product</Alert>}
        <Grid container spacing={2}>
          {data && currentData && data.map((item, idx) => {
            return (
              <Grid item xs={3}>
                <SingleMerchizeItem key={idx} cta={currentData.users_glbs_by_pk.linked_merchize_product === item.hasura_info.id?"Re order link":"Link Product"} count={idx} onClick={() => { handleClick(id, item.hasura_info.id) }} retail_price={item.retail_price} thumbnail={item.thumbnail} title={item.title}></SingleMerchizeItem>
              </Grid>
            )
          })}
        </Grid>
        {isError && <Alert severity='error'>An Error occured loading merchize items</Alert>}
        {loading && <Backdrop>
          <CircularProgress color='info'></CircularProgress>
        </Backdrop>}
      </RightPageDetailsNoLayout>
    </>
  )
}
