import React, { useRef, useState } from 'react'
import RightPageDetails from '../../layout/RightPageDetails.layout'
import style from "./allGameGlb.module.scss"
import { Alert, Box, Button, Fade, LinearProgress, Popover, Popper } from '@mui/material'
import { useQuery } from '@apollo/client'
import { LIST_OF_GAMES_GLB } from '../../graphql/listOfGamesGlb.query'
import { useParams } from 'react-router-dom'

export default function AllGameGlb() {
  const { id } = useParams()
  const { loading, data, error } = useQuery(LIST_OF_GAMES_GLB, { fetchPolicy: "no-cache", variables: { id } })
  return (
    <RightPageDetails title="List of game glb" subTitle="Glbs uploaded for a game">
      {loading && <LinearProgress color="info" />}
      {error && <Alert severity='error'>There was an error retrieving the list of glbs</Alert>}
      {data && <div id={style.allGameGlb}>
        <div className="top-text">
          <div>#</div>
          <div>Glb Name</div>
          <div>View Glb</div>
          <div>Update Glb</div>
          <div>delete</div>
        </div>
        {data.sdk_game_glbs.map((item, idx) => {
          return <SingleGlb idx={idx} item={item} />
        })}
      </div>}
    </RightPageDetails>
  )
}



function SingleGlb({ idx, item }) {
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleShowGlb = (e) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }
  return <div className="theListOfInfo">
    <div className="count">{idx + 1}</div>
    <div className="glb-name">{item.glb_name}</div>
    <div className="view">
      <Popover
        id={idx}
        open={open}
        anchorEl={anchorEl}
        onClose={() => { setOpen(false) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <model-viewer
          style={{ width: "300px", height: "300px", background: "white" }}
          alt="glb preview"
          src={item.glb_url}
          ar
          environment-image="/hdri/blue_photo_studio_1k.hdr"
          shadow-intensity="1"
          camera-controls
          touch-action="pan-y"
        ></model-viewer>
      </Popover>
      <Button variant="contained" color="primary" width="100%" sx={{ width: "100%" }} type="submit" ref={ref} onClick={handleShowGlb}>View Glb</Button>
    </div>
    <div className="update">
      <Button variant="contained" color="warning" width="100%" sx={{ width: "100%" }} type="submit">Update Glb</Button>
    </div>
    <div className="delete">
      <Button variant="contained" color="error" width="100%" sx={{ width: "100%" }} type="submit">Delete Glb</Button>
    </div>
  </div>
}

