import { gql } from "@apollo/client";

export const LIST_OF_MODS = (search = "") => {
    return gql`
query LIST_OF_MODS{
  user_moded_glb${
    search && `(where:{ ${search && `name: { _ilike: "%${search}%" }`} })`
  }{
    name
    id
    placeholder_image_url
    updated_at
    users_glb {
      merchize_product {
        product_id
      }
    }
}
}
`;
};