import React, { useContext, useState } from "react";
import styles from "./CardBlurPopup.module.scss";
import EditTexturePopUp from '../EditTexturePopUp/EditTexturePopUp'
import { handleTexture } from "../configurator/events/setTexture";
import { handleHdr } from "../configurator/events/setHdr";
import { homeContext } from "../../Home";

export default function CardBlurPopup(props) {
  const [showEditbiggerPopUp, setShowEditbiggerPopUp] = useState(false);
  const { setEditPosition }=useContext(homeContext)
  const { texture } = props
  const popUpBlur = () => {
    setEditPosition(true)
    handleTexture(texture)
  }

  const handleMedia = () => {
    if (props.hdr) {
      handleHdr(texture)
    }
    else {
      handleTexture(texture)
    }
  }



  return (
    <div className={styles.cardPopUpHover} >
      <div className="bgBlur"></div>
      <div className="bgTransparent">
        {!props.hdr && <div className="btn edit" onClick={popUpBlur} role="button">
          <img src="/images/move.png" alt="imagesCoton" /> Edit Position
        </div>}
        <div className="btn use" role="button" onClick={handleMedia}>
          <img src="/images/useTexture.png" alt="imagesCoton" /> {props.hdr ? "Use HDR" : "Use Texture"}
        </div>
      </div>
      {showEditbiggerPopUp && <EditTexturePopUp setShowEditbiggerPopUp={setShowEditbiggerPopUp} texture={texture} />}
    </div>
  );
}

