import React from 'react'
import styles from "./SingleCartItem.module.scss"
import { useDispatch } from 'react-redux'
import { decreaseCartItemCount, deleteCartItem, increaseCartItemCount } from '../../../../redux/cart/cartSlice'

export default function SingleCartItem({ item }) {
    const dispatch = useDispatch()
    const handleDelete = () => {
        dispatch(deleteCartItem({ id: item.id }))
    }
    const increaseCount = () => {
        dispatch(increaseCartItemCount({ id: item.id }))
    }
    const decreaseCount = () => {
        dispatch(decreaseCartItemCount({ id: item.id }))
    }
    return (
        <div id={styles.SingleCartItem}>
            <div className="p-s-details">
                <div className="cart-img" style={{ backgroundImage: `url("${item.data.user_moded_glb_by_pk.placeholder_image_url}")` }}></div>
                <div className="p-details">
                    <div className="p-name">{item.data.user_moded_glb_by_pk.name}</div>
                    <div className="p-size">size {item.selectedSize}</div>
                </div>
            </div>
            <div className="p-count">
                <div className="p-c-count">{item.count}</div>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{height:"20px",width:"auto"}}>
                    <path d="M14 6L7 0L0 6H14Z" fill="white" onClick={increaseCount} />
                    <path d="M14 8L7 14L0 8H14Z" fill="white" onClick={decreaseCount} />
                </svg>
            </div>
            <div className="p-price">{process.env.REACT_APP_CURRENCY} {item.data.retail_price}</div>
            <div className="p-delete" onClick={handleDelete}>
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.888889 14.2222C0.888889 15.2 1.68889 16 2.66667 16H9.77778C10.7556 16 11.5556 15.2 11.5556 14.2222V3.55556H0.888889V14.2222ZM12.4444 0.888889H9.33333L8.44444 0H4L3.11111 0.888889H0V2.66667H12.4444V0.888889Z" fill="white" />
                </svg>
            </div>
        </div>
    )
}
