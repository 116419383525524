import React, { useEffect, useState } from "react";
import styles from "./sideNav.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appRoutes } from "../../utils/appRoutes";
import { CLIENT, DEVELOPER, ENDUSER } from "../../utils/constants";
import { useSelector } from "react-redux";

export const navData = [
  { id: "1", title: "Upload Glb", img: "/images/glbLogo.svg", path: appRoutes.uploadGlb },
  { id: "2", title: "Manage Glbs", img: "/images/blockchain.svg", path: "/manage-glb" },
  { id: "3", title: "Upload Textures", img: "/images/img.svg", path: "/upload-texture" },
  { id: "4", title: "Manage Textures", img: "/images/mutilpleImg.svg", path: "/manage-textures" },
  { id: "5", title: "Upload Hrds", img: "/images/world.svg", path: "/upload-hrds" },
  { id: "6", title: "Manage Hrds", img: "/images/grid.svg", path: "/manage-hrds" },
];

export const endUserData = [
  { id: "1", title: "Manage Mods", img: "/images/glbLogo.svg", path: appRoutes.endUserGlbs },
  { id: "2", title: "List of products", img: "/images/blockchain.svg", path: appRoutes.listOfProducts },
  { id: "3", title: "Liked Products", img: "/images/favoritelike.svg", path: appRoutes.likedGlbs },
  { id: "4", title: "Orders", img: "/images/orders.svg", path: appRoutes.orders },
];

export const sdkDeveloperData = [
  { id: "1", title: "Create Game", img: "/images/glbLogo.svg", path: appRoutes.addGame },
  { id: "2", title: "Manage Games", img: "/images/blockchain.svg", path: appRoutes.listOfGames },
  { id: "3", title: "Logs", img: "/images/logs.svg", path: appRoutes.likedGlbs },
];

export default function SideNav(props) {
  const { navbarTitle } = props;
  const [titleDisplay, setTitleDisplay] = useState("");
  const [reduceNavSize, setReduceNavSize] = useState("");
  const pathLocation = useLocation();
  const userType = useSelector((state) => state.mainPageSliceReducer.currentUserType);

  useEffect(() => {
    if (!navbarTitle) {
      setTitleDisplay("dismissTitle");
      setReduceNavSize("reduceNavSize");
    } else {
      setTitleDisplay("");
      setReduceNavSize("");
    }
  }, [navbarTitle]);

  const dataToReturn = () => {
    if (userType === CLIENT) {
      return navData;
    }
    if (userType === ENDUSER) {
      return endUserData;
    }
    if (userType === DEVELOPER) {
      return sdkDeveloperData;
    }
    return [];
  };


  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("token")
    navigate(appRoutes.login)
  }

  return (
    <div className={styles.sideNav}>
      <div className={`content ${reduceNavSize}`}>
        <div className="imgeLogo">
          <img src="/images/logo.svg" alt="imageLogo" className="logoIcon" />
        </div>
        <div className="nav_body">
          <div className="nav_items">
            {dataToReturn()?.length &&
              dataToReturn().map((item) => {
                return (
                  <Link to={item.path} className={pathLocation.pathname === item.path ? "item active" : "item"} key={item.id}>
                    {pathLocation.pathname === item.path && <div className="activeBg"></div>}
                    <img src={item.img} alt=" icon" className="icon" />
                    <div className={`title ${titleDisplay}`}>{item.title}</div>
                  </Link>
                );
              })}
          </div>
          <div className="logoutBox" role="button" onClick={handleLogout}>
            <div className="item">
              <img src="/images/logout.svg" alt="logout" className="icon" />
              <div className={`title ${titleDisplay}`}>Logout</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
