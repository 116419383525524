import { Button, Typography } from "@mui/material";
import React from "react";
import styles from "./SuccessSubmition.module.scss";
import { useNavigate } from "react-router-dom";

export default function SuccessSubmition() {
  const navigate = useNavigate()
  const linkToLogin = () => {
    navigate('/login')
  }
  return (
    <div id={styles.successSubmition}>
      <div className="imageBox">
        <img src="/images/logoBlack.svg" alt="logo" />
      </div>
      <div className="body">
        <div className="imgTop"></div>
        <div className="bottom">
          <Typography variant="h3" className="title" sx={{ textAlign: "center" }}>
            Thanks For confirming email
          </Typography>
          <Typography variant="body2" color="secondary" sx={{ textAlign: "center" }} className="centered">
            We wanted to take a moment to thank you for confirming your email on
            Skinz. You can now continue to login and enjoy all features
            Skinz have for you.
          </Typography>
          <div className="centered">
            <Button variant="contained" color="primary" onClick={linkToLogin}>
              Continue to login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
