import React from 'react'
import styles from "./MerchizeProducts.module.scss"
import { SingleMerchizeItem } from './SingleMerchizeItem'
import { useQuery } from 'react-query'
import { apiRoutes, authetictedAxios } from '../../../../utils/api'
import { Alert, LinearProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery as apolloQuery } from '@apollo/client'
import { SINGLE_MOD_DETAILS } from '../../../../graphql/singleModDetails.query'

export default function MerchizeProducts() {
    const { isLoading, data, isError } = useQuery(apiRoutes.merchizeProducts, async () => {
        const res = await authetictedAxios().get(apiRoutes.merchizeProducts)
        if (res) {
            return res.data
        }
    },{
        refetchOnReconnect:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false,
    })

    const { id } = useParams()

    const { loading: modDetailsLoading, data: modDetailsData, error: modDetailsError } = apolloQuery(SINGLE_MOD_DETAILS, { variables: { id } })

    return (
        <div id={styles.MerchizeProducts}>
            {(isLoading || modDetailsLoading) && <LinearProgress color="info" />}
            <div className="list-of-products">
                {modDetailsData && data && data.map((item, idx) => {
                    return <SingleMerchizeItem id={idx} item={item} modDetails={modDetailsData} />
                })}
            </div>
            {(modDetailsError || isError) && <Alert severity="error">There was an error fetching list of products</Alert>}
        </div>
    )
}
