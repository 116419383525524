import React, { useState } from "react";
import styles from "./CustomizeStyler.module.scss";
import IconComponent from "../IconComponent/IconComponent";
import OutsideClickHandler from "react-outside-click-handler";
import SelectCardTexture from "../selectCardTexture/selectCardTexture";
import CardBlurPopup from '../CardBlurPopup/CardBlurPopup'
import ImageComponent from "../Environments/ImageComponent/ImageComponent";
import { loadCdn } from "../../../../utils/fileFetch";

const maximize = "/images/maximize-2.svg";
const legendaryLogo = "/images/dragonIcon.svg"

export default function CustomizeStyler(props) {
  const { Textures, setShowEditPopup } = props
  const [showSmallPopup, setShowSmallPopup] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [selectId, setSelectId] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [getValue, setGetValue] = useState({ logo: legendaryLogo, value: "Legendary" })
  const [texture, setTexture] = useState(null)
  // const [isPopupBlurOPen, setIsPopupBlurOPen] = useState(false);


  const handleClickedCard = (id, image, e) => {
    setTexture(image)
    setShowSmallPopup(true);
    setCardId(id);
  };

  const closeSelect = () => {
    if (showSelect) { setShowSelect(false) }
  };

  const handleShowSelect = (id) => {
    setShowSelect(true);
    setSelectId(id)

  };

  const handleClickOutsideCard = (id) => {
    setShowSmallPopup(true)
  }

  return (
    <div id={styles.customizeStyler}>
      {Textures &&
        Textures.map((card, i) => {
          return <div className="card" key={i} onClick={closeSelect}>
            <div className="card-top">
              {card.showCardActions && (
                <div className="boxActions">
                  <img
                    src={selectId === i ? getValue.logo : legendaryLogo}
                    alt="Logo Icon"
                    className="logoIcon"
                  />
                  <div className="bodyCardSelect">{selectId === i ? getValue.value : "Legendary"}</div>
                  {showSelect && selectId === i && <SelectCardTexture setGetValue={setGetValue} />}
                  <div className="edit" onClick={() => handleShowSelect(i)}>
                    <img src="/images/writIcon.svg" alt="edit Icon" className="editIcon" />
                    <div className="edit-blur"></div>
                  </div>
                </div>
              )}
              {card.showMaximize && (
                <div className="icon">
                  <IconComponent svg={maximize} />
                </div>
              )}
            </div>
            <OutsideClickHandler onOutsideClick={() => handleClickOutsideCard(i)}>
              <div className="compontent" onClick={(e) => handleClickedCard(i, card.imageSrc, e)} >
                {props.textureImage && <ImageComponent background={card.imageSrc} />}
                {props.hdr && <model-viewer style={{ width: "100%", height: "100%" }} alt="glb preview" src={loadCdn("defaults/hdriTestGlb.glb")} ar environment-image={card.imageSrc} skybox-image={card.imageSrc} shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>}
              </div>
            </OutsideClickHandler>
            {showSmallPopup && cardId === i && card.name !== null && (
              <>
                <CardBlurPopup id={i} texture={texture}  {...props} hdrTexture={card?.hdr} />
              </>
            )}
            <div className="card-bottom">
              {card.number && <div className="number">{card.number}</div>}
              {card.name && <div className="Name">{card.name}</div>}
            </div>
          </div>
        })}
    </div>
  );
}
