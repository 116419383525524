export const ACCEPTED_IMAGE_SIZE = 10 * 1024 * 1024;
export const ACCEPTED_PROFILE_IMAGE_SIZE = 2 * 1024 * 1024;
export const SUPPORTED__IMAGE_FORMATS = [
  "image/jpeg",
  "image/png",
  "image/webp",
];
export const SUPPORTED__HDR_FORMATS = [".hdr", "image/vnd.radiance"];
export const ACCEPTED_IMAGE_MB = "10MB";
export const ACCEPTED_PROFILE_IMAGE_MB = "2MB";

export const ACCEPTED_GLB_SIZE = 50 * 1024 * 1024;
export const ACCEPTED_GLB_MB = "50MB";
export const SUPPORTED__GLB_FORMATS = [
  "model/gltf-binary",
  "model/gltf+json",
  ".glb",
  ".gltf",
];

export const APP_CONTAINER_CLASS = ".app";

export const CLIENT = "client";

export const ENDUSER = "enduser";

export const DEVELOPER = "sdk_developer";

export const INTERNALS = "internals";

export const imagesLocalStorageKey = "images";

export const fabricCanvasName = "fabricCanvas";
