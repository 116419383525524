import React from 'react'
import RightPageDetails from '../../layout/RightPageDetails.layout'
import { Alert, Button, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, TextareaAutosize, TextField } from '@mui/material'
import { useFormik } from 'formik'
import style from "./addGame.module.scss"
import * as Yup from "yup"
import { useMutation } from '@apollo/client'
import { INSERT_GAME } from '../../graphql/insertGame.mutation'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../utils/appRoutes'

export default function AddGame() {
    // insert game
    const [addGame, { loading, data, error }] = useMutation(INSERT_GAME)
    const { handleChange, values, errors, touched, handleSubmit } = useFormik({
        validationSchema: Yup.object().shape({
            appName: Yup.string().required("Please enter the app name"),
            website: Yup.string().url("The website link should be a valid url").required("Website url is required"),
            devEnv: Yup.string().required("Please select one of these dev environments"),
            plan: Yup.string().required("The usage for the app is required")
        }),
        initialValues: {
            appName: "",
            website: "",
            devEnv: "",
            plan: ""
        },
        onSubmit: async (values) => {
            await addGame({
                variables: {
                    environment: values.devEnv,
                    name: values.appName,
                    notes: values.plan,
                    website_url: values.website
                }
            })
        }
    })
    const listOfPlatforms = [
        "Unreal Engine", "Android Native", "Web", "React", "Unity", "IOS Native", "React Native", "Other"
    ]
    const radioStyle = {
        color: "#C4FF03",
        '& path': {
            fill: "#C4FF03",
        },
        '&.Mui-checked': {
            color: "#C4FF03",
        },
    }

    const navigate = useNavigate()

    return (
        <RightPageDetails title="Add Game" subTitle="Add a game name">
            <div style={{ marginBottom: "20px" }}>
                {loading && <LinearProgress color='info' />}
                {data && navigate(appRoutes.listOfGames)}
                {error && <Alert severity='error'>There was an error adding the app.</Alert>}
            </div>
            <form id={style.addGame} onSubmit={handleSubmit}>
                <div className="form-item">
                    <label>App name <span className="required"></span></label>
                    <TextField about="official-form" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name="appName" value={values.appName} helperText={touched.appName && errors.appName ? errors.appName : ""} />
                </div>
                <div className="form-item">
                    <label>Website <span className="required"></span></label>
                    <TextField about="official-form" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name="website" value={values.website} helperText={touched.website && errors.website ? errors.website : ""} />
                </div>
                <div className="form-item">
                    <label>Primary development environment <span className="required"></span></label>
                    <div>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="devEnv"
                            style={{
                                marginLeft: "32px"
                            }}
                            onChange={handleChange}
                            value={values.devEnv}
                        >
                            <Grid container spacing={2}>
                                <Grid xs={6}>
                                    {listOfPlatforms.slice(0, 4).map((platform) => {
                                        return <div><FormControlLabel value={platform} control={<Radio color="success" />} label={platform} sx={radioStyle} /></div>
                                    })}
                                </Grid>
                                <Grid xs={6}>
                                    {listOfPlatforms.slice(4, 8).map((platform) => {
                                        return <div><FormControlLabel value={platform} control={<Radio />} label={platform} sx={radioStyle} /></div>
                                    })}
                                </Grid>
                            </Grid>
                        </RadioGroup>
                        {touched.devEnv && errors.devEnv ? <div className='error-text'>{errors.devEnv}</div> : ""}
                    </div>

                </div>
                <div className="form-item">
                    <label>how are you planning to use skinz in your game<span className="required"></span></label>
                    <div style={{ width: "100%", display: "grid" }}>
                        <TextareaAutosize aria-label="minimum height" minRows={10} placeholder="Minimum 3 rows" about="official-form" onChange={handleChange} name="plan" value={values.plan} />
                        {touched.plan && errors.plan ? <div className='error-text'>{errors.plan}</div> : ""}
                    </div>
                </div>
                <Grid container>
                    <Grid xs={11}>
                    </Grid>
                    <Grid xs={1}>
                        <Button variant="contained" color="success" width="100%" sx={{ width: "100%" }} type="submit">Add</Button>
                    </Grid>
                </Grid>

            </form>
        </RightPageDetails>
    )
}
