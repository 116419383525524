import React from 'react'
import styles from "./Hero.module.scss"
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../../../utils/appRoutes'

export default function Hero() {
    const history = useNavigate()
    return (
        <div id={styles.hero} style={{
            backgroundImage: `url("/images/back_hero.png")`
        }}>
            <div></div>
            <div className="hero-text">E-COMMERCE FOR THE OWNERSHIP ECONOMY</div>
            <div className="cta">
                <button className="btn cta-btn" onClick={() => { history(appRoutes.listOfProducts) }}>CONFIGURE NOW</button>
            </div>
        </div>
    )
}
