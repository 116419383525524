import { gql } from "@apollo/client";

export const LIST_OF_USER_GENERATIONS = gql`
  query LIST_OF_USER_GENERATIONS{
    user_generations(order_by: { created_at: desc }) {
      id
      prompt
      resultant_image
      style
      created_at
    }
  }
`;