import * as React from "react";
import styles from "./FormUpload.module.scss";
import { Button, Typography, TextField, Alert, Select, Skeleton, MenuItem } from "@mui/material";
import { useRef, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ACCEPTED_GLB_MB,
  ACCEPTED_GLB_SIZE,
  ACCEPTED_IMAGE_MB,
  ACCEPTED_IMAGE_SIZE,
  SUPPORTED__IMAGE_FORMATS,
} from "../../../../utils/constants";
import { useGlbUpload } from "./useGlbUpload";
import { Context } from "../../../../components/dashboardLayout/FormLayout.layout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SINGLE_GLB_INFO } from "../../../../graphql/singleGlbInfo.query";
import { useParams } from "react-router-dom";
import CircularProgressComponent from "../../../../components/circularProgress/CircularProgress.component";
import NotFound from "../../../../components/404/404";
import FileUploadComponent from "../../../../components/FileUpload/FileUploadComponent.component";
import { LIST_OF_CATEGORY } from "../../../../graphql/listOfCategory.query";
import Autocomplete from '@mui/material/Autocomplete';

const UploadFormForward = React.forwardRef(function FormUpload(props,ref) {
  const { setGlbPreview, setPlaceholderPreview } = props;
  const [placeholderImageName, setPlaceholderImageName] = useState(null);
  const [glbImageName, setGlbImageName] = useState("");

  const handleRemoveGlb = () => {
    setGlbPreview(null);
    setGlbImageName(null);
  };
  const handleRemovePlaceholder = () => {
    setPlaceholderPreview(null);
    setPlaceholderImageName(null);
  };

  const submitBtnRef = useRef();
  const handleSubmitBtn = (e, setFieldValue, value, formikValues) => {
    if (uploadablePreview) {
      setFieldValue("placeholderImage", uploadablePreview);
    }
    setFieldValue("isPrivate", value);
    submitBtnRef.current.click();
  };


  const validationSchema = Yup.object().shape({
    glbName: Yup.string().required("glb name is Required"),
    productSku: Yup.string().required("product SKU Required"),
    category: Yup.number().required("category is Required"),
    glbFile: Yup.mixed()
      .required("A glb file is required")
      .test(
        "fileSize",
        `file size must be ${ACCEPTED_GLB_MB} or smaller`,
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          return value && value.size <= ACCEPTED_GLB_SIZE;
        }
      ),
    placeholderImage: Yup.mixed()
      .required("A placeholder Image file is required")
      .test(
        "fileSize",
        `file size must be ${ACCEPTED_IMAGE_MB} or smaller`,
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          return value && value.size <= ACCEPTED_IMAGE_SIZE;
        }
      )
      .test(
        "fileFormat",
        `Supported formats: ${SUPPORTED__IMAGE_FORMATS.join(", ")}`,
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          return value && SUPPORTED__IMAGE_FORMATS.includes(value.type);
        }
      )
  });

  // isEdit
  const { edit, uploadablePreview, setUploadablePreview, setGlbImage } = React.useContext(Context);
  const [glbInfo, { loading, data, error }] = useLazyQuery(SINGLE_GLB_INFO, {
    fetchPolicy: "no-cache",
  });
  const { id } = useParams();

  const defaultValues = {
    glbName: "",
    productSku: "",
    placeholderImage: null,
    glbFile: null,
    isPrivate: false,
    category: null
  };
  const [initialValues, setInitialValues] = useState(defaultValues);
  const handleInitialValue = async () => {
    if (edit) {
      const result = await glbInfo({ variables: { id } });
      if (result.data) {
        const { users_glbs_by_pk: glbSingle } = result.data;
        setGlbPreview(glbSingle.glb_file_url);
        setGlbImageName(glbSingle.glb_file_url.split("/").pop());
        setPlaceholderPreview(glbSingle.placeholder_image_url);
        setPlaceholderImageName(
          glbSingle.placeholder_image_url.split("/").pop()
        );
        return {
          glbName: glbSingle.name,
          productSku: glbSingle.SKU,
          placeholderImage: glbSingle.placeholder_image_url,
          glbFile: glbSingle.glb_file_url,
          isPrivate: glbSingle.private,
          category: glbSingle.glb_category.id,
        };
      }
    }
    return defaultValues;
  };
  React.useEffect(() => {
    const _ = async () => {
      const result = await handleInitialValue();
      setInitialValues(result);
    };
    _();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loadingState,
    errorState,
    dataState,
    handleGlbUpload: uploadHandler,
  } = useGlbUpload(edit);
  const handleSubmit = (values) => {
    uploadHandler(values);
  };

  const { loading: categoryLoading, data: categoryData, error: categoryError } = useQuery(LIST_OF_CATEGORY)

  return (
    <>
      {edit && loading && <CircularProgressComponent onTop={true} />}
      {edit && error && (
        <NotFound
          title="Error fetching glb info"
          body={`Issue detected: Invalid .glb ID, network error, or sign-in token. Check inputs, connectivity, or sign in again. Contact support if issues persist.`}
        />
      )}
      {((edit && data) || (!edit && initialValues)) && (
        <>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => {
              const handlePlaceholderChange = (files) => {
                const selectedFile = files[0];
                setUploadablePreview(null)
                setGlbImage(null)
                setFieldValue("placeholderImage", selectedFile);
                if (selectedFile) {
                  const blobUrl = URL.createObjectURL(selectedFile);
                  setPlaceholderImageName(selectedFile.name);
                  setPlaceholderPreview(blobUrl);
                } else {
                  setPlaceholderPreview(null);
                  setPlaceholderImageName(null);
                }
              };
              const handleGlbChange = (files) => {
                const selectedFile = files[0];
                setFieldValue("glbFile", selectedFile);
                if (selectedFile) {
                  const blobUrl = URL.createObjectURL(selectedFile);
                  setGlbImageName(selectedFile.name);
                  setGlbPreview(blobUrl);
                } else {
                  setGlbPreview(null);
                  setGlbImageName(null);
                }
              };
              const glbFileProps = {
                handleRemoveGlb: handleRemoveGlb,
                handleGlbChange: handleGlbChange,
                fileUrl: glbImageName,
                errors: errors.glbFile,
                touched: touched.glbFile,
                title: "Glb file",
                subTitle: " (Add Glb that will be used for configuration)",
                fileTypes: ".glb,.gltf",
                mimeTypes: {
                  'model/gltf-binary': ['.glb'],
                  'model/gltf+json': ['.gltf']
                },
                maxFileSize: 50,
                dropTitle: "Upload the GLB file to be customized",
                uploadSvg: "/images/glbLogo.svg",
                fileType: "Glb"
              };

              const imageFileProps = {
                handleRemoveGlb: handleRemovePlaceholder,
                handleGlbChange: handlePlaceholderChange,
                fileUrl: placeholderImageName,
                errors: errors.placeholderImage,
                touched: touched.placeholderImage,
                title: "Glb Placeholder Image",
                subTitle:
                  " (Add an image that will be used as the glb placeholder)",
                fileTypes: ".jpeg,.png,.webp",
                mimeTypes: {
                  'image/jpeg': ['.jpeg'],
                  'image/png': ['.png'],
                  'image/webp': ['.webp'],
                },
                maxFileSize: 2,
                dropTitle: "Upload The glb placeholder image",
                uploadSvg: "/images/img.svg",
                fileType: "Image"
              };

              return (
                <Form className={styles.formUpload} autoComplete="off">
                  <div className="form_content">
                    {loadingState && (
                      <Alert severity="info">{loadingState}</Alert>
                    )}
                    {errorState && (
                      <Alert severity="error">
                        An error occured when trying to upload glb
                      </Alert>
                    )}
                    {dataState && (
                      <Alert severity="success">
                        Glb uploaded successfully
                      </Alert>
                    )}
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">
                          Glb name&nbsp;&nbsp;
                        </Typography>
                        <div className="opacity">
                          <Typography
                            variant="body2"
                            className="label"
                            color="secondary"
                          >
                            (add a general name that best describe your glb you
                            are uploading)
                          </Typography>
                          <Typography
                            variant="body2"
                            className="label purple"
                            about="purple"
                          >
                            *
                          </Typography>
                        </div>
                      </div>
                      <TextField
                        variant="outlined"
                        classes={{ root: "input" }}
                        placeholder='Example: "Davinci dress"'
                        onChange={handleChange}
                        value={values.glbName}
                        name="glbName"
                      />
                      {errors.glbName && touched.glbName ? (
                        <Typography
                          variant="body2"
                          className="label"
                          color="red"
                        >
                          {errors.glbName}
                        </Typography>
                      ) : null}
                    </>
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">
                          Category&nbsp;&nbsp;
                        </Typography>
                        <div className="opacity">
                          <Typography
                            variant="body2"
                            className="label"
                            color="secondary"
                          >
                            (Choose a category associated with the glb)
                          </Typography>
                          <Typography
                            variant="body2"
                            className="label purple"
                            about="purple"
                          >
                            *
                          </Typography>
                        </div>
                      </div>
                      {categoryLoading && <Skeleton variant="rect" about="rounded" height={56} />}
                      {categoryError && <Alert severity="error" about='preview'>An error occured while fetching list of glbs please check your internet connection or signin again</Alert>}
                      {categoryData && (
                        <Autocomplete
                          options={categoryData.glb_categories}
                          getOptionLabel={(option) => option.title}
                          onChange={(event, newValue) => handleChange({ target: { name: "category", value: newValue?.id || "" } })}
                          value={categoryData.glb_categories.find((item) => item.id === values.category) || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select a category"
                              variant="outlined"
                              placeholder="Select a category"
                            />
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                      )}
                      {errors.category && touched.category ? (<Typography variant="body2" className="label" color='red'>{errors.category}</Typography>) : null}
                    </>
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">
                          Product SKU&nbsp;&nbsp;
                        </Typography>
                        <div className="opacity">
                          <Typography
                            variant="body2"
                            className="label"
                            color="secondary"
                          >
                            (add a product SKU to better track this product in
                            your inventory)
                          </Typography>
                          <Typography
                            variant="body2"
                            className="label purple"
                            about="purple"
                          >
                            *
                          </Typography>
                        </div>
                      </div>
                      <TextField
                        variant="outlined"
                        classes={{ root: "input" }}
                        placeholder='Example: "189320304"'
                        onChange={handleChange}
                        name="productSku"
                        value={values.productSku}
                      />
                      {errors.productSku && touched.productSku ? (
                        <Typography
                          variant="body2"
                          className="label"
                          color="red"
                        >
                          {errors.productSku}
                        </Typography>
                      ) : null}
                    </>
                    <FileUploadComponent {...glbFileProps} />
                    <FileUploadComponent {...imageFileProps} />
                  </div>
                  <div className="buttons">
                    <button type="submit" hidden ref={submitBtnRef}></button>
                    <div></div>
                    <Button
                      type="submit"
                      className="btn final"
                      variant="contained"
                      color="primary"
                      value={false}
                      onClick={(e) => {
                        handleSubmitBtn(e, setFieldValue, false, values);
                      }}
                      disabled={Boolean(loadingState)}
                      ref={ref}
                    >
                      Publish Final Glb
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
})

export default UploadFormForward
