import { gql } from "@apollo/client";

export const UPDATE_SELECTED_PRODUCT = gql`
  mutation UPDATE_SELECTED_PRODUCT($id: uuid!, $linked_merchize_product: Int!) {
    update_users_glbs_by_pk(
      pk_columns: { id: $id }
      _set: { linked_merchize_product: $linked_merchize_product }
    ) {
      linked_merchize_product
    }
  }
`;
