import React, { createContext } from "react";
import styles from "./Checkout.module.scss";
import RightPageDetails from "../../layout/RightPageDetails.layout";
import { Alert, Backdrop, Checkbox, CircularProgress, TextareaAutosize } from "@mui/material";
import { AddressInfo, AddressValidation, AdrressInitialValues, notRequiredAddress } from "./AddressInfo.jsx";
import * as Yup from "yup"
import { OrderDetails } from "./OrderDetails.jsx";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useMutation as useAppolloMutaion } from "@apollo/client";
import { apiRoutes, authetictedAxios } from "../../utils/api.js";
import { useNavigate, useParams } from "react-router-dom";
import { appRoutes } from "../../utils/appRoutes.js";
import { ADD_ORDER_DETAILS } from "../../graphql/addOrderDetails.mutation.js";

export const CHECKOUT_CONTEXT = createContext()

export default function Checkout() {

  const [addOrderDetails] = useAppolloMutaion(ADD_ORDER_DETAILS)
  const { id } = useParams()
  const { mutate, isLoading, isError, data } = useMutation(apiRoutes.payForCartItems, async (values) => {
    const res = await addOrderDetails({
      variables: {
        "cart_id": id,
        "city": values.billingAddress.town,
        "company_name": values.billingAddress.companyName,
        "email_address": values.billingAddress.emailAddress,
        "first_name": values.billingAddress.firstName,
        "last_name": values.billingAddress.lastName,
        "order_notes": values.orderNotes,
        "phone": values.billingAddress.phone,
        "ship_city": values.shippingAddress.town,
        "ship_company_name": values.shippingAddress.companyName,
        "ship_email_address": values.shippingAddress.emailAddress,
        "ship_first_name": values.shippingAddress.firstName,
        "ship_last_name": values.shippingAddress.lastName,
        "ship_phone": values.shippingAddress.phone,
        "ship_street_address": values.shippingAddress.streetAddress,
        "street_address": values.shippingAddress.streetAddress,
        "country": values.shippingAddress.country,
        "ship_country": values.billingAddress.country,
        "postcode": values.billingAddress.postcode,
        "ship_postcode": values.shippingAddress.postcode,
        "state": values.billingAddress.state,
        "ship_state": values.shippingAddress.state,
        "address_2": values.billingAddress.address_2,
        "ship_address_2": values.shippingAddress.address_2,
      }
    })
    if (res.data && res.data.insert_order_personal_details_one.id) {
      const res = await authetictedAxios().post(apiRoutes.payForCartItems, {
        cartId: id
      })
      if (res) {
        return res.data
      }
    }
  })

  // formik validations
  const validationSchema = Yup.object().shape({
    shipToDifferentAddres: Yup.boolean().optional(),
    orderNotes: Yup.string().optional(),
    billingAddress: AddressValidation,
    shippingAddress: AddressValidation.when("shipToDifferentAddres", {
      is: true,
      then: schema => {
        return schema.required()
      },
      otherwise: schema => {
        return notRequiredAddress
      },
    }),
  })
  const initialValues = {
    shipToDifferentAddres: false,
    orderNotes: "",
    billingAddress: { ...AdrressInitialValues },
    shippingAddress: AdrressInitialValues,
  }
  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!values.shipToDifferentAddres) {
        values.shippingAddress = { ...values.billingAddress }
      }
      mutate(values)
    }
  })

  const navigate = useNavigate()

  return (
    <RightPageDetails title="Checkout" subTitle="Buy Your items Now">
      <CHECKOUT_CONTEXT.Provider value={{ handleChange, values, errors, touched }}>
        <form onSubmit={handleSubmit}>
          {isLoading && <Backdrop open={true} sx={{ zIndex: "100" }}>
            <CircularProgress color="info"></CircularProgress>
          </Backdrop>}
          {data && navigate(appRoutes.paymentFunc(id, data.paymentLink))}
          <div id={styles.checkout}>
            <div className="part-1">
              {isError && <Alert severity="error">There was an error fecthing payment information</Alert>}
              <div className="heading">BILLING DETAILS</div>
              <AddressInfo parent="billingAddress" />
              <div className="check-box-flex">
                <Checkbox about="checkout" aria-describedby="checkout" title="checkout" name="shipToDifferentAddres" onChange={handleChange} />
                <label>Ship to a different address?</label>
              </div>
              {values.shipToDifferentAddres && <AddressInfo parent="shippingAddress" />}
              <div className="form-item">
                <label>Order Notes</label>
                <TextareaAutosize aria-label="minimum height" minRows={10} placeholder="Minimum 3 rows" about="checkout" />
              </div>
            </div>
            <OrderDetails />
          </div>
        </form>
      </CHECKOUT_CONTEXT.Provider>
    </RightPageDetails>
  );
}

