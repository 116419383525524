import React from 'react'
import { createPortal } from 'react-dom'

export default function Portal({ children }) {
    return (
        <>
            {createPortal(children, document.querySelector(`#${process.env.REACT_APP_FABRIC_CANVAS_PORTAL_ID}`))}
        </>
    )
}
