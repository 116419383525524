import React from 'react'
import styles from "./CartCalculation.module.scss"
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { apiRoutes, authetictedAxios } from '../../../../utils/api'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../../../utils/appRoutes'
import { CircularProgress } from '@mui/material'
import { handleClose } from '../../../../redux/cart/cartSlice'

export default function CartCalculation() {
    const cartItems = useSelector((state) => state.cartSliceReducer.cartItems)
    const handleCalc = () => {
        let final = 0
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i];
            final = final + (item.data.retail_price * item.count)
        }
        return final
    }

    const navigate = useNavigate()
    const dispatch=useDispatch()
    const { mutate, isLoading } = useMutation(apiRoutes.addCartItems, async () => {
        const res = await authetictedAxios().post(apiRoutes.addCartItems, {
            cartItems: cartItems.map((item) => {
                return {
                    mod_id: item.data.user_moded_glb_by_pk.id,
                    variant: item.selectedSize,
                    count: item.count,
                }
            })
        })
        if (res.data) {
            dispatch(handleClose(true))
            navigate(appRoutes.checkoutFunc(res.data.cartId))
            return res.data
        }
    })
    return (
        <div id={styles.CartCalculation}>
            <div className="items">
                <div className="single-item">
                    <div className="i-name">Subtotal</div>
                    <div className="i-price">{process.env.REACT_APP_CURRENCY} {handleCalc()}</div>
                </div>
                <div className="single-item">
                    <div className="i-name">Shipping</div>
                    <div className="i-price">{process.env.REACT_APP_CURRENCY} 0</div>
                </div>
                <div className="single-item">
                    <div className="i-name">Total</div>
                    <div className="i-price">{process.env.REACT_APP_CURRENCY} {handleCalc()}</div>
                </div>
            </div>
            <button className='checkout-btn' onClick={mutate}>{isLoading ? <CircularProgress size={16} /> : "Checkout"}</button>
        </div>
    )
}
