import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import styles from "./ManageMods.module.scss";
import { useQuery } from "@apollo/client";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { LIST_OF_MODS } from "../../graphql/listOdUserGlbs.query";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "../../components/topNav/topNav.slice";
import { SingleModItem } from "./components/SingleModItem/SingleModItem";

export default function ManageMods() {
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const [searchValue, setSearchValue] = useState(searchInputValue);

  const dispatch = useDispatch();
  const handleReset = () => {
    setSearchValue("");
    dispatch(setSearchInput(""));
  };
  useEffect(() => setSearchValue(searchInputValue), [searchInputValue]);
  const { data: listOfGlbs, refetch } = useQuery(LIST_OF_MODS(searchValue), { fetchPolicy: "no-cache" });

  const handleSeachFilter = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Moded Products</div>
            <div className="sub_title">Products that you liked previously </div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Moded Products
              </Typography>
              <div className="searchBox">
                <input type="text" className="search_input" placeholder="Search Mod" value={searchValue} onChange={(e) => handleSeachFilter(e)} />
                <img src="/images/search.svg" alt="search" className="search" />
              </div>
            </div>
            {listOfGlbs && listOfGlbs.user_moded_glb.length === 0 && (
              <EmptyFilter
                setSearchValue={handleReset}
                title="No Matching Mods Found"
                desc="We're sorry, but the mods search and filter you performed did not return any results. We recommend reviewing the filters you're using or resetting them altogether. To reset your mods search filter, please click the button below:"
              />
            )}
            <div className="list-of-mods">
              {listOfGlbs && listOfGlbs.user_moded_glb.length !== 0 && listOfGlbs.user_moded_glb.map((item, id) => {
                return (
                  <SingleModItem id={id} item={item} key={id} refetch={refetch} />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}


