import { gql } from "@apollo/client";

export const ADD_USER_GLB_MOD = gql`
  mutation ADD_USER_GLB_MOD(
    $name: String!
    $placeholder_image_url: String!
    $image_key: String!
    $print_pattern_key: json!
    $glb_key: String!
    $glb_url: String!
    $source_glb: uuid!
  ) {
    insert_user_moded_glb_one(
      object: {
        name: $name
        placeholder_image_url: $placeholder_image_url
        image_key: $image_key
        print_pattern_setting: $print_pattern_key
        glb_key: $glb_key
        glb_url: $glb_url
        source_glb: $source_glb
      }
    ) {
      id
    }
  }
`;
