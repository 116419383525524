import { gql } from "@apollo/client";

export const UPDATE_MOD_ID = gql`
  mutation UPDATE_MOD_ID(
    $transferred_glb: String!
    $merchize_product_id: Int!
    $mod_id: Int!
  ) {
    update_user_moded_glb_by_pk(
      _set: {
        transferred_glb: $transferred_glb
        merchize_product_id: $merchize_product_id
      }
      pk_columns: { id: $mod_id }
    ) {
      id
    }
  }
`;