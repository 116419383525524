import { gql } from "@apollo/client";

export const LIST_OF_GAMES_GLB = gql`
  query LIST_OF_GAMES_GLB($id: uuid!) {
    sdk_game_glbs(where: { sdk_game_id: { _eq: $id } }) {
      glb_key
      glb_name
      glb_url
      id
      created_at
      sdk_game_id
      updated_at
    }
  }
`;
