import styles from "./SingleMerchizeItem.module.scss"
import { useMutation } from "react-query"
import { useMutation as useApolloMutation } from "@apollo/client";
import { apiRoutes, authetictedAxios } from "../../../../utils/api";
import { Backdrop, Button, CircularProgress, Snackbar } from "@mui/material";
import { Fragment, useState } from "react";
import { UPDATE_MOD_ID } from "../../../../graphql/updateModId.mutation";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../../../utils/appRoutes";

export function SingleMerchizeItem({ id, item, modDetails }) {

    const [updateMod] = useApolloMutation(UPDATE_MOD_ID)
    const navigate = useNavigate()

    const { isLoading, data, isError, mutate } = useMutation(apiRoutes.textureTransfer, async () => {
        const result = await authetictedAxios().post(apiRoutes.textureTransfer, {
            "source_glb": modDetails.user_moded_glb_by_pk.glb_file_url,
            "target_glb": item.hasura_info.product_glb
        })
        if (result) {
            await updateMod({ variables: { transferred_glb: result.data.final_glb, merchize_product_id: item.hasura_info.id, mod_id: modDetails.user_moded_glb_by_pk.id } })
            const performBake = await authetictedAxios().post(apiRoutes.bakeTexture, {
                mod_id: modDetails.user_moded_glb_by_pk.id
            })
            navigate(appRoutes.productDetailsFunc(modDetails.user_moded_glb_by_pk.id))
            return performBake.data
        }
    })

    // handling snackbar
    const [snackBarState, setSnackBarState] = useState(true)
    const handleCloseSnackBar = () => {
        setSnackBarState(false)
    }
    const action = (
        <Fragment>
            <Button
                color="info" size="small" onClick={handleCloseSnackBar}>
                CLOSE
            </Button>
        </Fragment>
    );

    console.log("teh status", isLoading, data, isError)
    return (
        <div className={styles.SingleMerchizeItem} key={id} style={{
            backgroundImage: `linear-gradient(2.76deg, rgba(31, 146, 253, 0.69) -9.43%, rgba(248, 247, 255, 0.332713) 42.96%, rgba(255, 255, 255, 0) 123.71%),url("${item.thumbnail}")`
        }}>
            {isLoading && <Backdrop open={true} sx={{ zIndex: 1000 }}>
                <div style={{
                    display: "grid",
                    gap: "12px",
                    placeItems: "center"
                }}>
                    <div style={{ width: "20px" }}><CircularProgress color="info" /></div>
                    <p style={{ color: "white", fontSize: "24px" }}>Please don't close this tab while we process your request</p>
                </div>
            </Backdrop>}
            {isError && <Snackbar open={snackBarState}
                autoHideDuration={6000}
                message="Error occurred when performing texture transfer"
                action={action} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}></Snackbar>}
            <div className="top">
                <div className="numbering">{id + 1 < 10 ? `0${id + 1}` : id + 1}</div>
            </div>
            <div className="bottom">
                <div className="product-name">{item.title}</div>
                <div className="product-price">{process.env.REACT_APP_CURRENCY} {item.retail_price}</div>
                <div className="buy-now-btn" role="button" onClick={mutate}>
                    Transfer To This
                </div>
            </div>
        </div>
    );
}