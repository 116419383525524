import { createContext } from "react";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { Outlet } from "react-router-dom";
import GaurdClientRoutes from "../../gaurds/GaurdClientRoutes";


export const UploadGlbContext = createContext()

export default function UploadGlb(props) {

    return (
    <GaurdClientRoutes>
        <UploadGlbContext.Provider values={{ ...props }}>
            <DashboardLayout>
                <Outlet />
            </DashboardLayout>
        </UploadGlbContext.Provider>
    </GaurdClientRoutes>
    );
}
