import React, { createContext, useState } from "react";
import styles from "./StableDiffusion.module.scss";
import { Button, CircularProgress, FormLabel, MenuItem, Select, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDetails } from "./autoGenerated.slice";
import { useStableDiffusion } from "./useStableDiffusion";
import { SingleGeneratedImage } from "./SingleGeneratedImage";
import { diffusionStyles } from "./constants";
import { LIST_OF_USER_GENERATIONS } from "../../../../graphql/listOfUserGenerations";
import { useQuery } from "@apollo/client";
import AutheticatedGraphql from "../../../../providers/AutheticatedGraphql";

export const STABLE_DIFFUSION_CONTEXT = createContext()

function StableDiffusion() {
  const [texture, setTexture] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [showSmallPopup, setShowSmallPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const detailState = useSelector((state) => state.imageDetailsReducer.details);
  const popUpContent = useSelector((state) => state.imageDetailsReducer.cardPopUp);

  const handleChangeDetails = (e) => {
    dispatch(setDetails(e.target.value));
  };

  const { loading, error, handlePrompt, images } = useStableDiffusion();
  const handleClickedCard = (id, image, e) => {
    setTexture(image);
    setShowSmallPopup(true);
    setCardId(id);
  };

  const handleClickOutsideCard = (id) => {
    setShowSmallPopup(false);
  };

  const handleClickOpenPopOver = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCardId(id);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  //load the content into editor
  const handleLoadIntoEditor = () => {
    dispatch(setDetails(popUpContent));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [style, setStyle] = useState("tile-texture")
  const handleStyleChange = (e) => {
    setStyle(e.target.value)
  }

  const { loading: loadingQueries, data, refetch } = useQuery(LIST_OF_USER_GENERATIONS)

  const handleSubmitImageDetails = (e) => {
    e.preventDefault();
    handlePrompt(detailState, style, refetch);
  };


  return (
    <div className={styles.autoGenerated}>
      <STABLE_DIFFUSION_CONTEXT.Provider value={{
        showSmallPopup, cardId, handleClickOutsideCard, texture, handleLoadIntoEditor, handleClickedCard, handleClickOpenPopOver, id, open, anchorEl, handleClosePopOver, setAnchorEl, handlePrompt, refetch
      }}>
        <form action="POST" onSubmit={handleSubmitImageDetails} className="form">
          <div className="choose_style">
            <FormLabel about="black">Prompt</FormLabel>
            <textarea name="details" id="details" value={detailState} onChange={handleChangeDetails} className="details" placeholder="vintage ornate mushroom and foliage wallpaper risograph print halftone pattern blue and red" rows={5}></textarea>
          </div>
          <div className="choose_style">
            <FormLabel about="black">Style</FormLabel>
            <Select
              value={style}
              label="Choose Styles"
              onChange={handleStyleChange}
              about="white"
            >
              {diffusionStyles.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>
              })}
            </Select>
          </div>
          <div className="action">
            <Button variant="contained" color="primary" type="submit" sx={{ borderRadius: "0px" }}>
              Generate
            </Button>
          </div>
        </form>
        <div className="container_images">
          <Typography variant="h4">Generated AI Images</Typography>
          <div className="images_box">
            {loading && (
              <div className="loading-card">
                <CircularProgress />
              </div>
            )}
            {error && (
              <div className="error-card">
                <div className="erro-text-container">
                  <img src="/images/oopsError.svg" alt="robot error" />
                  <div className="error">We were enable to generate the image we are working on the issue ASAP</div>
                </div>
              </div>
            )}
            {data && (
              data.user_generations.map((singleCard, idx) => {
                const card = { ...singleCard }
                card.id = idx + 1;
                card.image = card.resultant_image
                card.url = card.resultant_image
                const props = { card }
                return <SingleGeneratedImage {...props} key={idx} />;
              })
            )}
            {!loadingQueries && !data && images &&
              images.map((card, idx) => {
                card.id = idx + 1;
                const props = { card };
                return <SingleGeneratedImage {...props} key={idx} />;
              })}
            {Array.apply(null, Array(Math.max(0, 6 - (data ? data.user_generations.length : false || images.length)))).map((card, idx) => {
              return <div key={idx} className="empty-card" alt="AI generated placeholder" style={{ backgroundImage: "url('/images/emptyAiGeneration.svg')" }} />;
            })}
          </div>
        </div>
      </STABLE_DIFFUSION_CONTEXT.Provider>
    </div>
  );
}

export default function StableDiffusionGraphql() {
  return (
    <AutheticatedGraphql>
      <StableDiffusion></StableDiffusion>
    </AutheticatedGraphql>
  )
};
