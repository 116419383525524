import React, { useState } from 'react'
import { SketchPicker } from 'react-color';
import { handleColor } from '../configurator/events/setColor';

export default function ColorPicker() {
    const [color, setColor] = useState("#fff")
    const handleChangeComplete = (color, event) => {
        setColor(color.hex);
        handleColor(color.hex)
    };

    return (
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}><SketchPicker color={color} onChangeComplete={handleChangeComplete} width='70%' disableAlpha={true} presetColors={[]} /></div>
    )
}
