import React from "react";
import styles from "./RightPageDetails.module.scss";
import DashboardLayout from "../components/dashboardLayout/dashboardLayout";

export default function RightPageDetails({ children, title, subTitle }) {
    return (
        <DashboardLayout>
            <div className={styles.RightPageDetails}>
                <div className="upload">
                    <div className="top_content">
                        <div className="title">{title}</div>
                        <div className="sub_title">{subTitle}</div>
                    </div>
                    <div className="uploaded_glb">
                        <div className="the-container">{children}</div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
