import React from 'react'
import RightPageDetails from '../../layout/RightPageDetails.layout'
import { useQuery } from '@apollo/client'
import { LIST_OF_GAMES } from '../../graphql/listOfGames.query'
import { Alert, Button, LinearProgress } from '@mui/material'
import style from "./listOfGames.module.scss"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../utils/appRoutes'
import singleGlbStyle from "./singleGame.module.scss"

export default function ListOfGames() {
    const { pathname } = useLocation()
    const isTransferRoute = pathname.includes(appRoutes.rootChooseGameToTransferTo)
    const { loading, data, error } = useQuery(LIST_OF_GAMES, { fetchPolicy: "no-cache" })
    const title = isTransferRoute ? {
        title: "Game To Transfer to",
        subTitle: "Choose a game you want to transfer your garment to "
    } : {
        title: "List of games",
        subTitle: "All games you created"
    }
    return (
        <RightPageDetails {...title}>
            {loading && <LinearProgress color='info' />}
            {error && <Alert severity='error'>There was an error retrieving list of games</Alert>}
            {data && <>
                <div id={style.listOfgames}>
                    {data && data.sdk_games.map((item) => {
                        return <SingleGame devenv={item.environment} name={item.name} id={item.id} isTransferRoute={isTransferRoute} />
                    })}
                </div>
            </>}
        </RightPageDetails>
    )
}
export function SingleGame({ devenv, name, id, isTransferRoute }) {
    const navigate = useNavigate()
    const params = useParams()
    return (<div className={singleGlbStyle.singleGame}>
        {!isTransferRoute && <div className="top">
            <div className="devenv">{devenv}</div>
            <img src="/images/edit_game.svg" alt="edit_game" />
        </div>}
        <div className="game_name">{name}</div>
        {isTransferRoute ? (<div className="bottom">
            <div className="add_game_glbs">
                <img src="/images/transfer.svg" alt="transfer game" />
                <Button className="info-text" onClick={() => navigate(appRoutes.transferToGameFunc(id, params.id))} sx={{ color: "white !important" }}>Choose Asset</Button>
            </div>
        </div>) : (<div className="bottom">
            <div className="add_game_glbs">
                <img src="/images/add.svg" alt="add game" />
                <Button className="info-text" onClick={() => navigate(appRoutes.addGameGlbsFunc(id))} sx={{ color: "white !important" }}>Add Game Glbs</Button>
            </div>
                <Button className="info-text" onClick={() => navigate(appRoutes.allGameGlbFunc(id))} sx={{ color: "#a297c2 !important" }}>List Of Glbs</Button>
        </div>)}
    </div>)
}

