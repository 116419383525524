import React, { useContext } from 'react'
import styles from "./SingleMatch.module.scss"
import { MatchContext } from './MatchGlb'

export default function SingleMatch({ glb_url, patterns, glb_id }) {
    const { match, setMatch } = useContext(MatchContext)
    const setPatternMatch = (id) => {
        const recentMatch = { ...match }
        const keys = Object.keys(recentMatch)
        for (let i = 0; i < keys.length; i++) {
            const item = recentMatch[keys[i]];
            if (item === id) {
                if (recentMatch[keys[i]]){
                    delete recentMatch[keys[i]]
                }
            }
        }
        console.log("the glb id", glb_id,id,recentMatch)
        recentMatch[glb_id] = id
        setMatch(recentMatch)
    }
    return (
        <div id={styles.SingleMatch}>
            <div className="glb-container">
                <model-viewer
                    style={{ width: "100%", height: "100%" }}
                    alt="glb preview"
                    src={glb_url}
                    ar
                    environment-image="/hdri/blue_photo_studio_1k.hdr"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                ></model-viewer>
            </div>
            <svg width="64" height="45" viewBox="0 0 64 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.1137 0.0786743H0.0214844V44.4984H63.1137V0.0786743Z" fill="black" />
                <path d="M25.6529 22.7821H37.4827M37.4827 22.7821L31.7354 28.7047M37.4827 22.7821L31.7354 16.8595" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div className="pattern-container">
                {patterns.map((item) => {
                    return <div className={`single-svg-pattern `} style={{
                        backgroundImage: `url("${item.pattern_url}")`
                    }} onClick={() => setPatternMatch(item.id)}>
                        {match[glb_id] === item.id && <div className='active'></div>}
                    </div>
                })}
            </div>
        </div>
    )
}
