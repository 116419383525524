import { TextField } from "@mui/material";
import { useContext } from "react";
import * as Yup from "yup"
import { CHECKOUT_CONTEXT } from "./Checkout";
export const AddressValidation = Yup.object().shape({
    firstName: Yup.string().required("Your first name is required."),
    lastName: Yup.string().required("Your last name is required."),
    companyName: Yup.string().optional("Enter a valid company name"),
    country: Yup.string().required("Please enter the address of the country"),
    streetAddress: Yup.string().required("Please enter the details of the street."),
    town: Yup.string().required("The town is required"),
    phone: Yup.string().required("Your phone number is required"),
    emailAddress: Yup.string().email("Please eneter a valid email Address").required("Your email Address is required"),
    address_2: Yup.string().optional(),
    state: Yup.string().optional(),
    postcode: Yup.string().required("Please enter your postcode"),
})
export const notRequiredAddress = Yup.object().shape({
    firstName: Yup.string().notRequired(),
    lastName: Yup.string().notRequired(),
    companyName: Yup.string().optional(),
    country: Yup.string().notRequired(),
    streetAddress: Yup.string().notRequired(),
    town: Yup.string().notRequired(),
    phone: Yup.string().notRequired(),
    emailAddress: Yup.string().notRequired(),
    address_2: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    postcode: Yup.string().notRequired(),
})
export const AdrressInitialValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    streetAddress: "",
    town: "",
    phone: "",
    emailAddress: "",
    address_2: "",
    state: "",
    postcode: "",
}
export function AddressInfo({ parent }) {
    const { handleChange, errors, touched } = useContext(CHECKOUT_CONTEXT)
    const getName = (name) => `${parent}.${name}`
    return <>
        <div className="form-flex">
            <div className="form-item">
                <label>First name <span className="required"></span></label>
                <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("firstName")} helperText={touched[parent]?.firstName && errors[parent]?.firstName ? errors[parent]?.firstName : null} />
            </div>
            <div className="form-item">
                <label>Last name <span className="required"></span></label>
                <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("lastName")} helperText={touched[parent]?.lastName && errors[parent]?.lastName ? errors[parent]?.lastName : null} />
            </div>
        </div>
        <div className="form-item">
            <label>Company name (optional)</label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("companyName")} helperText={touched[parent]?.companyName && errors[parent]?.companyName ? errors[parent]?.companyName : null} />
        </div>
        <div className="form-item">
            <label>Country / Region</label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("country")} helperText={touched[parent]?.country && errors[parent]?.country ? errors[parent]?.country : null} />
        </div>
        <div className="form-item">
            <label>Address line 1 <span className="required"></span></label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("streetAddress")} helperText={touched[parent]?.streetAddress && errors[parent]?.streetAddress ? errors[parent]?.streetAddress : null} />
        </div>
        <div className="form-item">
            <label>Address line 2</label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("address_2")} helperText={touched[parent]?.address_2 && errors[parent]?.address_2 ? errors[parent]?.address_2 : null} />
        </div>
        <div className="form-item">
            <label>State/Province</label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("state")} helperText={touched[parent]?.state && errors[parent]?.state ? errors[parent]?.state : null} />
        </div>
        <div className="form-item">
            <label>Town / City <span className="required"></span></label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("town")} helperText={touched[parent]?.town && errors[parent]?.town ? errors[parent]?.town : null} />
        </div>
        <div className="form-item">
            <label>Postcode <span className="required"></span></label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("postcode")} helperText={touched[parent]?.postcode && errors[parent]?.postcode ? errors[parent]?.postcode : null} />
        </div>
        <div className="form-item">
            <label>Phone <span className="required"></span></label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("phone")} helperText={touched[parent]?.phone && errors[parent]?.phone ? errors[parent]?.phone : null} />
        </div>
        <div className="form-item">
            <label>Email address <span className="required"></span></label>
            <TextField about="checkout" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name={getName("emailAddress")} helperText={touched[parent]?.emailAddress && errors[parent]?.emailAddress ? errors[parent]?.emailAddress : null} />
        </div>
    </>
}