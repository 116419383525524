export const setTheInfoCanvas = (selectedMesh, glbMatchInfo) => {
  const material = selectedMesh.material.name;
  const glbMaterial = glbMatchInfo.glbRes;
  const theMatchInfo = glbMatchInfo.input;
  const thePatternInfo = glbMatchInfo.patternRes;
  //   const patternInfo=
  let the_selected_obj = null;
  let the_pattern_info = null;
  for (let i = 0; i < glbMaterial.length; i++) {
    const item = glbMaterial[i];
    if (item.material_name.toLowerCase() === material.toLowerCase()) {
      the_selected_obj = item;
      const the_match_obj = theMatchInfo[item.id];
      for (let i = 0; i < thePatternInfo.length; i++) {
        const j = thePatternInfo[i];
        if (j.id === the_match_obj) {
          the_pattern_info = j;
        }
      }
    }
  }
  return { the_selected_obj, the_pattern_info };
};
