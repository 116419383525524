import React from "react";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import RightPageDetails from "../../layout/RightPageDetails.layout";
import MerchizeProducts from "./components/products/MerchizeProducts";


export default function MerchizeProductsPage(props) {
    return (
        <DashboardLayout>
            <RightPageDetails title="Choose Product To Transfer To" subTitle="From the below list of products you can choose the product you need to transfer to.">
                <MerchizeProducts />
            </RightPageDetails>
        </DashboardLayout>
    );
}
