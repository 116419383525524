import React, { useContext, useEffect, useRef } from "react";
import * as THREE from "three";
import { handleGlb } from "./loaders/handleGlb";
import { setupCamera } from "./render/createCamera";
import { createRenderer } from "./render/createRenderer";
import { createOrbitControls } from "./render/handleControls";
import { renderScene } from "./render/handleRender";
import styles from "./configurator.module.scss";
import { useDivResize } from "./hooks/useDivResize";
import { getClickedMesh } from "./customization/handleMeshClick";
import { CHANGE_TEXTURE } from "./events/setTexture";
import { loadHDRI } from "./loaders/handleHdr";
import { homeContext } from "../../Home";
import { MOVE_TEXTURE } from "./events/handleTextureMoving";
import { cleanScene } from "./cleanup/cleanScene";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SET_HDR } from "./events/setHdr";
import { initializeComposer } from "./postProcessing/initialiaze";
import { outlinePass } from "./postProcessing/passes/outlinePass";
import { brightnessPass } from "./postProcessing/passes/brightnessPass";
import { handleLookAtMesh } from "./customization/handleLookAtMesh";
import { CHANGE_COLOR } from "./events/setColor";
import { EXPORT_GLB } from "./events/exportGlb";
import { exportSceneAsGLB } from "./export/exportGlb";
import {
  USE_FABRIC_CANVAS,
  fabricCanvasHandler,
} from "./events/useFabricCanvas";
import { setTheInfoCanvas } from "./customization/setTheInfoCanvas";
import { handleTexture } from "../patternEditor/events/fireNewTexture";
import { useParams } from "react-router-dom";
import { handleStats } from "./render/handleStats";
import { handleColorChangeFabric } from "../patternEditor/events/changeColor";

const Configurator = ({ container, glb }) => {
  const containerRef = useRef(null);
  const scene = useRef();
  const camera = useRef();
  const renderer = useRef();
  const controls = useRef();
  const selectedObject = useRef();
  const LoadingManager = useRef();
  const composerRef = useRef();
  const currentTextureRef = useRef();
  const statsRef = useRef();

  const { width, height } = useDivResize(container);
  const containerDimensions = useRef({ width, height });
  const { id } = useParams();

  const {
    uvCanvasRef,
    setPercentage,
    percentage,
    setLoadingPopup,
    patternInfo,
    setSelectedPattern,
  } = useContext(homeContext);

  useEffect(() => {
    LoadingManager.current = new THREE.LoadingManager();
    LoadingManager.current.onProgress = function (_, itemsLoaded, itemsTotal) {
      setPercentage((itemsLoaded / itemsTotal) * 100);
    };
    // setup webgl objects
    scene.current = new THREE.Scene();
    renderer.current = createRenderer(
      containerRef.current,
      containerDimensions.current.width,
      containerDimensions.current.height
    );
    camera.current = setupCamera(
      containerDimensions.current.width,
      containerDimensions.current.height
    );
    const outlinePassPass = outlinePass(
      containerDimensions.current.width,
      containerDimensions.current.height,
      scene.current,
      camera.current
    );
    composerRef.current = initializeComposer(
      renderer.current,
      scene.current,
      camera.current,
      [outlinePassPass, brightnessPass()]
    );
    controls.current = createOrbitControls(
      camera.current,
      containerRef.current
    );

    handleGlb(
      glb,
      scene.current,
      LoadingManager.current,
      containerRef.current,
      renderer.current,
      camera.current,
      controls.current,
      patternInfo
    );
    loadHDRI(
      "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/yoga_room_1k.hdr",
      renderer.current,
      scene.current,
      LoadingManager.current
    );

    statsRef.current = handleStats();
    renderScene(statsRef, composerRef.current, controls);

    const handleTextureMaterial = (e) => {
      if (!currentTextureRef.current) {
        return;
      }
      handleTexture(currentTextureRef.current, {
        texture: e.detail.texture,
        selectedObject,
      });
      if (selectedObject.current) {
        handleLookAtMesh(
          camera.current,
          selectedObject.current,
          controls.current
        );
      }
    };
    const handleTextureMove = (e) => {
      if (selectedObject.current.material.map) {
        const map = selectedObject.current.material.map;
        const { detail } = e;
        map.repeat.set(detail.repeatX, detail.repeatY);
        map.wrapS = THREE.RepeatWrapping;
        map.wrapT = THREE.RepeatWrapping;
        map.offset.set(detail.offSetX / 600, detail.offSetY / 600);
      }
    };
    const handleHdrChange = (e) => {
      loadHDRI(
        e.detail.hdr,
        renderer.current,
        scene.current,
        LoadingManager.current,
        setPercentage
      );
    };
    const windowResize = () => {
      // Update the camera's aspect ratio
      const { width, height } = container.getBoundingClientRect();
      containerDimensions.current.width = width;
      containerDimensions.current.height = height;
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
      // Update the renderer's size
      renderer.current.setSize(width, height);
      composerRef.current.setSize(width, height);
      outlinePassPass.resolution = new THREE.Vector2(width, height);
      renderer.current.setPixelRatio(window.devicePixelRatio);
    };
    const handleColorChange = (e) => {
      const color = e.detail.color;
      if (selectedObject.current) {
        handleLookAtMesh(
          camera.current,
          selectedObject.current,
          controls.current
        );
        handleColorChangeFabric(currentTextureRef.current, {
          color,
          selectedObject,
        });
      }
    };
    const handleGlbExport = (e) =>
      exportSceneAsGLB(
        patternInfo,
        e.detail.name,
        setLoadingPopup,
        scene.current,
        id,
        controls.current,
        camera.current
      );
    const handleFabric = (e) =>
      fabricCanvasHandler({
        canvasname: e.detail.canvasid,
        selectedObject: selectedObject.current,
      });

    function handleClicked(event) {
      const clickedMesh = getClickedMesh(
        event,
        camera.current,
        scene.current,
        containerDimensions.current.width,
        containerDimensions.current.height,
        container
      );
      if (clickedMesh) {
        selectedObject.current = clickedMesh;
        const { the_pattern_info } = setTheInfoCanvas(
          selectedObject.current,
          patternInfo
        );
        console.log("teh pateer info", the_pattern_info);
        if (the_pattern_info) {
          setSelectedPattern(the_pattern_info.id);
          outlinePassPass.selectedObjects = [clickedMesh];
          const canvasEl = document.getElementById(the_pattern_info.id);
          const texture = new THREE.CanvasTexture(canvasEl);
          texture.encoding = THREE.sRGBEncoding;
          texture.needsUpdate = true;
          texture.flipY = false;
          texture.mapping = THREE.UVMapping;
          clickedMesh.material.map = texture;
          currentTextureRef.current = canvasEl;
        }
      }
    }
    var containerVar = containerRef.current;
    containerVar.addEventListener("click", handleClicked);
    document.addEventListener(CHANGE_TEXTURE, handleTextureMaterial);
    document.addEventListener(MOVE_TEXTURE, handleTextureMove);
    document.addEventListener(SET_HDR, handleHdrChange);
    document.addEventListener(CHANGE_COLOR, handleColorChange);
    document.addEventListener(EXPORT_GLB, handleGlbExport);
    document.addEventListener(USE_FABRIC_CANVAS, handleFabric);
    window.addEventListener("resize", windowResize);
    document.addEventListener("fullscreenchange", windowResize);
    document.addEventListener("webkitfullscreenchange", windowResize);
    document.addEventListener("mozfullscreenchange", windowResize);
    document.addEventListener("MSFullscreenChange", windowResize);
    return () => {
      cleanScene(renderer.current, scene.current, controls.current);
      containerVar.removeEventListener("click", handleClicked);
      document.removeEventListener(CHANGE_TEXTURE, handleTextureMaterial);
      document.removeEventListener(MOVE_TEXTURE, handleTextureMove);
      document.removeEventListener(SET_HDR, handleHdrChange);
      document.removeEventListener(CHANGE_COLOR, handleColorChange);
      document.removeEventListener(EXPORT_GLB, handleGlbExport);
      document.removeEventListener(USE_FABRIC_CANVAS, handleFabric);
      window.removeEventListener("resize", windowResize);
      document.removeEventListener("fullscreenchange", windowResize);
      document.removeEventListener("webkitfullscreenchange", windowResize);
      document.removeEventListener("mozfullscreenchange", windowResize);
      document.removeEventListener("MSFullscreenChange", windowResize);
    };
  }, [container, setPercentage, uvCanvasRef]);

  return (
    <div id={styles.configurator}>
      {percentage !== 100 && (
        <div className="preloader-container" style={{ width, height }}>
          <div style={{ width: 90, height: 90 }}>
            <CircularProgressbar
              value={parseInt(percentage)}
              strokeWidth={3}
              text={`${parseInt(percentage)}%`}
            />
          </div>
        </div>
      )}
      <canvas
        ref={containerRef}
        id={process.env.REACT_APP_CONFIGURATOR_ID}
      ></canvas>
    </div>
  );
};

export default Configurator;
