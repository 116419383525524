import React from 'react'
import RightPageDetails from '../../layout/RightPageDetails.layout'
import style from "./addGameGlbs.module.scss"
import { Alert, Button, Grid, LinearProgress, TextField } from '@mui/material'
import * as Yup from "yup"
import { useFormik } from 'formik'
import FileUploadComponent from '../../components/FileUpload/FileUploadComponent.component'
import { ACCEPTED_GLB_MB, ACCEPTED_GLB_SIZE } from '../../utils/constants'
import { useMutation } from 'react-query'
import { apiRoutes, authetictedAxios } from '../../utils/api'
import { v4 } from 'uuid'
import axios from 'axios'
import { useMutation as useAppolloMutaion } from '@apollo/client'
import { INSERT_GAME_MUTATION } from '../../graphql/insertGameGlb.mutation'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../utils/appRoutes'

export default function AddGameGlbs() {
    const { id } = useParams()
    const navigate = useNavigate()

    const [addGameGlb] = useAppolloMutaion(INSERT_GAME_MUTATION)
    const { isLoading, isError, mutate, data } = useMutation("upload_glb" + Math.random(), async (values) => {
        const fileUploadUrlResponse = await authetictedAxios().post(
            apiRoutes.getSignedUrl,
            {
                filename: `developerUploads/${v4()}.glb`,
                filetype: "application/json",
            }
        );
        if (fileUploadUrlResponse?.data?.signedUrl) {
            const fileUploadUrl = fileUploadUrlResponse.data.signedUrl;
            const blob = new Blob([values.glbFile], { type: "application/json" })
            await axios.put(fileUploadUrl, blob, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const url = fileUploadUrlResponse.data.url
            const key = fileUploadUrlResponse.data.key
            const res = await addGameGlb({
                variables: {
                    glb_key: key,
                    glb_name: values.glbName,
                    glb_url: url,
                    sdk_game_id: id
                }
            })
            return res.data
        }
    })
    const { handleChange, values, errors, touched, handleSubmit, setFieldValue } = useFormik({
        validationSchema: Yup.object().shape({
            glbName: Yup.string().required("Please enter the glb name"),
            glbFile: Yup.mixed()
                .required("A glb file is required")
                .test(
                    "fileSize",
                    `file size must be ${ACCEPTED_GLB_MB} or smaller`,
                    (value) => {
                        if (typeof value === "string") {
                            return true;
                        }
                        return value && value.size <= ACCEPTED_GLB_SIZE;
                    }
                ),
        }),
        initialValues: {
            glbName: "",
            glbFile: "",
            fileUrl: {}
        },
        onSubmit: async (values) => {
            mutate(values)
        }
    })

    const handleGlbChange = (files) => {
        const selectedFile = files[0];
        setFieldValue("glbFile", selectedFile);
        if (selectedFile) {
            const blobUrl = URL.createObjectURL(selectedFile);
            setFieldValue("fileUrl", { name: selectedFile.name, blobUrl });
        } else {
            setFieldValue("fileUrl", {});
        }
    };
    const handleRemoveGlb = () => {
        setFieldValue("glbFile", null);
        setFieldValue("fileUrl", null);
    }

    const glbFileProps = {
        handleRemoveGlb,
        handleGlbChange,
        fileUrl: values.fileUrl?.name,
        errors: errors.glbFile,
        touched: touched.glbFile,
        title: "",
        subTitle: "",
        fileTypes: ".glb,.gltf",
        mimeTypes: {
            'model/gltf-binary': ['.glb'],
            'model/gltf+json': ['.gltf']
        },
        maxFileSize: 50,
        dropTitle: "Upload the game glb to be used",
        uploadSvg: "/images/glbLogo.svg",
        fileType: "Glb",
        customLabel: true
    };
    return (
        <RightPageDetails title="Upload Game Glb" subTitle="Upload the game glb that a user can texture transfer to">
            <div style={{ marginBottom: "20px" }}>
                {isLoading && <LinearProgress color='info' />}
                {isError && <Alert severity='error'>There was an error creating a glb</Alert>}
                {data && (navigate(appRoutes.allGameGlbFunc(id)))}
            </div>
            <Grid container id={style.addGameGlbs} spacing={4}>
                <Grid item xs={8} >
                    <form onSubmit={handleSubmit}>
                        <div className="form-item">
                            <label>Glb name <span className="required"></span></label>
                            <TextField about="official-form" id="standard-basic" label="Standard" variant="standard" onChange={handleChange} name="glbName" value={values.glbName} helperText={touched.glbName && errors.glbName ? errors.glbName : ""} />
                        </div>
                        <div className="form-item">
                            <label>Add glb file<span className="required"></span></label>
                            <FileUploadComponent {...glbFileProps} />
                        </div>
                        <div>
                            <Button variant="contained" color="success" width="20%" sx={{ width: "20%" }} type="submit">Add Glb</Button>
                        </div>
                    </form>
                </Grid>
                <Grid item xs={4} >
                    <div className="form-item">
                        <label>Glb preview</label>
                        <div style={{ backgroundImage: `url('/images/upload_glb_image.svg')`, width: "100%", height: "40vh", backgroundPosition: "center", backgroundSize: "cover" }}>
                            {values.fileUrl ? <model-viewer
                                style={{ width: "100%", height: "40vh", background: "white" }}
                                alt="glb preview"
                                src={values.fileUrl.blobUrl}
                                ar
                                environment-image="/hdri/blue_photo_studio_1k.hdr"
                                shadow-intensity="1"
                                camera-controls
                                touch-action="pan-y"
                            ></model-viewer> : ""}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </RightPageDetails>
    )
}
