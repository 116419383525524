import React, { createContext, useState } from "react";
import styles from "./productDetails.module.scss";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { useParams } from "react-router-dom";
import { GLB_MERCHIZE_ID, SINGLE_MOD_DETAILS } from "../../graphql/singleModDetails.query";
import { useLazyQuery } from "@apollo/client";
import { useQuery } from "react-query"
import { SelectSizes } from "./SelectSizes";
import { CartBtns } from "./CartBtns";
import { SizeGuide } from "./SizeGuide";
import { apiRoutes, authetictedAxios } from "../../utils/api";
import { Alert, LinearProgress } from "@mui/material";

export const PRODUCT_DETAILS_CONTEXT = createContext()


export default function ProductDetails() {
  const { id } = useParams()
  const [singleModDetails] = useLazyQuery(SINGLE_MOD_DETAILS, {
    variables: {
      id
    }
  })
  const [singleGlbInfo] = useLazyQuery(GLB_MERCHIZE_ID)
  const [currentImage, setCurrentImage] = useState("3d")
  const [selectedSize, setSelectedSize] = useState(null)

  const { isLoading, data, isError } = useQuery(apiRoutes.singleMerchizeProduct + "product_glbs" + parseInt(id), async () => {
    await authetictedAxios().post(apiRoutes.createMerchizePattern, {
      mod_id: parseInt(id)
    })
    const modDetails = await singleModDetails()
    const glbInfo = await singleGlbInfo({
      variables: {
        id: modDetails.data.user_moded_glb_by_pk.source_glb
      }
    })
    const res = await authetictedAxios().get(apiRoutes.singleMerchizeProductFunc(glbInfo.data.users_glbs_by_pk.merchize_product.product_id))
    if (res) {
      return { ...res.data, ...modDetails.data }
    }
  }, {
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  })

  return (
    <DashboardLayout>
      <PRODUCT_DETAILS_CONTEXT.Provider value={{
        selectedSize, setSelectedSize, data
      }}>
        <div className={styles.manageGlb}>
          <div className="upload">
            <div className="top_content">
              <div className="title">Product details</div>
              <div className="sub_title">details of your product</div>
            </div>
            <div className="uploaded_glb">
              {isLoading && <LinearProgress color="info" />}
              {isError && <Alert severity="error" color="error" >An error occurred fetching the product details</Alert>}
              {data && <section className="product-details">
                <div className="image-preview">
                  <div className="main-image" style={{
                    backgroundImage: `${currentImage !== "3d" ? `url("${currentImage}")` : ""}`
                  }} >
                    {currentImage === "3d" && <model-viewer
                      style={{ width: "100%", height: "100%" }}
                      alt="glb preview"
                      src={data.user_moded_glb_by_pk.glb_url}
                      ar
                      environment-image="/hdri/blue_photo_studio_1k.hdr"
                      shadow-intensity="1"
                      camera-controls
                      touch-action="pan-y"
                    ></model-viewer>}
                  </div>
                  <div className="image-to-preview">
                    <div
                      className={`single-image-to-preview ${currentImage === "3d" ? "active" : ""}`}
                      style={{ backgroundImage: `url("/images/3d_product_details.png")`, backgroundSize: "cover" }}
                      onClick={() => setCurrentImage("3d")}
                    ></div>
                    <div
                      className={`single-image-to-preview ${currentImage === data.user_moded_glb_by_pk.placeholder_image_url ? "active" : ""}`}
                      style={{ backgroundImage: `url("${data.user_moded_glb_by_pk.placeholder_image_url}")` }}
                      onClick={() => setCurrentImage(data.user_moded_glb_by_pk.placeholder_image_url)}
                    ></div>
                    <div
                      className={`single-image-to-preview ${currentImage === data.user_moded_glb_by_pk.baked_pattern ? "active" : ""}`}
                      style={{ backgroundImage: `url("${data.user_moded_glb_by_pk.baked_pattern}")` }}
                      onClick={() => setCurrentImage(data.user_moded_glb_by_pk.baked_pattern)}
                    ></div>
                  </div>
                </div>
                <div className="product-desc">
                  {data && <>
                    <div className="flex-title">
                      <div className="product-title">{data.user_moded_glb_by_pk.name}</div>
                      <div className="product-price">{process.env.REACT_APP_CURRENCY} {data.retail_price}</div>
                    </div>
                    <SelectSizes />
                    <SizeGuide />
                    <CartBtns />
                  </>}
                </div>
              </section>}
            </div>
          </div>
        </div>
      </PRODUCT_DETAILS_CONTEXT.Provider>
    </DashboardLayout >
  );
}