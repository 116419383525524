import React, { useState, useLayoutEffect, useRef, useContext,useEffect } from "react";
import styles from "./PageManIconPart.module.scss";
import IconComponent from "../IconComponent/IconComponent";
import Configurator from "../configurator/Configurator";
import { exportGlb } from "../configurator/events/exportGlb";
import { useParams, useSearchParams } from "react-router-dom";
import { SINGLE_GLB_INFO } from "../../../../graphql/singleGlbInfo.query";
import { useQuery } from "@apollo/client";
import { useQuery as useTanQuery } from "react-query";
import NotFound from "../../../../components/404/404";
import { Button, CircularProgress, Popover, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setNameEdit } from "./fieldInput.slice";
import { homeContext } from "../../Home";
import OrderProduct from "../OrderProduct/OrderProduct";
import { apiRoutes, authetictedAxios } from "../../../../utils/api";
import { PatternEditor } from "../patternEditor/PatternEditor";
import { useFullScreen } from "./useFullScreen";

//getting images of icons
export const rotate = "/images/3d_rotation.svg";
export const addCicleOutline = "/images/add-circled-outline.svg";
export const shirt = "/images/shirt.svg";
export const brain = "/images/brain-cloud.svg";
export const maximize = "/images/maximize-2.svg";
export const save = "/images/save.svg";
export const saveBlack = "/images/saveBlack.svg";

export default function PageManIconPart({ setRenderCustomize }) {
  const [isReady, setIsReady] = useState(false);
  const [saveGlbStateElement, setSaveGlbStateElemenet] = useState(null);
  const { setLoginAndSignPopup, setPatternInfo, activeIcon, setActiveIcon } = useContext(homeContext)


  const dispatch = useDispatch();
  const nameEdit = useSelector((state) => state.inputFieldSlicesReducers.nameEdit);

  const handleSaveGlbOpenPopOver = (target) => {
    setSaveGlbStateElemenet(target);
  };

  const openSave = Boolean(saveGlbStateElement);
  const idClickedIconPopOver = openSave ? "shareCustomize" : undefined;

  const handleCloseSavePopOver = () => {
    setSaveGlbStateElemenet(null);
  };

  const IconComponentDataLeft = [
    { svg: rotate, id: "rotate3D" },
    { svg: shirt, id: "shirtCustomize" },
    { svg: addCicleOutline, id: "addCustomize" },
    { svg: brain, id: "colorPalette" },
  ];


  // handling fullscreen
  const container = useRef(null);
  const { toggleFullscreen } = useFullScreen(container)

  const IconComponentDataRight = [
    { svg: maximize, id: "maximizeCustomize", onClick: () => { toggleFullscreen() } },
    {
      svg: saveBlack,
      id: "shareCustomize",
      onClick: (e) => {
        if (nameFromUrl) {
          return exportGlb({
            name: nameFromUrl,
            edit: Boolean(nameFromUrl),
            id: modIdFromUrl,
            glbId: id,
          });
        }
        return handleSaveGlbOpenPopOver(e.currentTarget);
      },
    },
  ];
  const token = localStorage.getItem('token');
  const handleSubmitSaveGlb = (e) => {
    e.preventDefault();
    if (!token) {
      setLoginAndSignPopup({
        message: "You don't have rights to access this feature Unless you logged in!"
      });
      setSaveGlbStateElemenet(null);
    } else {
      handleGlbExport();
    }
  };

  const showActiveIcon = (svg, id) => {
    setActiveIcon(svg);
    setRenderCustomize(id);
  };

  useLayoutEffect(() => {
    setIsReady(true);
  }, []);

  const { id } = useParams();
  const { loading, data, error } = useQuery(SINGLE_GLB_INFO, { variables: { id } });
  const [searchParams] = useSearchParams();
  const glbFromUrl = searchParams.get("glbToUse");
  const nameFromUrl = searchParams.get("name");
  const modIdFromUrl = searchParams.get("modId");

  const handleGlbExport = () => {
    exportGlb({
      name: nameFromUrl || nameEdit,
      edit: Boolean(nameFromUrl),
      id: modIdFromUrl,
      glbId: id,
    });
  };

  const [buyProduct, setBuyProduct] = useState(false)

  const { isLoading, data: matchData, isError } = useTanQuery(apiRoutes.matchGlbAndPatternInfo + JSON.stringify(data ? data : {}), async () => {
    const res = await authetictedAxios().post(apiRoutes.matchGlbAndPatternInfo, {
      match_info_json: data.users_glbs_by_pk.pattern_match
    })
    if (res) {
      setPatternInfo(res.data)
      return res.data
    }
  }, {
    enabled: data ? data.users_glbs_by_pk.pattern_match ? true : false : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  })


  return (
    <div id={styles.pageManIconPart} ref={container}>
      {loading && isLoading && (
        <div className="glbLoading">
          <CircularProgress />
        </div>
      )}
      {/* render the fabric canvas to be sued for images processing  */}
      {matchData && matchData.patternRes.map((item) => {
        const { id, source_coordinates, pattern_url } = item
        return (
          <PatternEditor canvasId={id} width={source_coordinates.width} height={source_coordinates.height} pattern_url={pattern_url} />
        )
      })}
      <>{data && matchData && <Configurator container={container.current} glb={glbFromUrl || data.users_glbs_by_pk.glb_file_url} />}</>
      {error && isError && (
        <div className="glbError">
          <NotFound
            body="Oops! The GLB you're looking for isn't available. Please check the URL and try again. If you believe this is an error, please contact our support team for assistance. Thank you for your understanding."
            title="Unable to find GLb related to this Id"
          />
        </div>
      )}
      <div className="icons">
        <div className="iconBox">
          {IconComponentDataLeft.map((icon, idx) => (
            <div className="SinglIcon" key={idx} onClick={() => showActiveIcon(icon.svg, icon.id)}>
              <IconComponent svg={icon.svg} active={activeIcon === icon.svg ? "active" : ""} />
            </div>
          ))}
        </div>
        <div className="iconBox">
          {IconComponentDataRight.map((icon, idx) => (
            <>
              <div className="SinglIcon" key={idx} aria-describedby={idClickedIconPopOver} onClick={icon.onClick} >
                <IconComponent svg={icon.svg} active={activeIcon === icon.svg ? "active" : ""} />
              </div>
              {icon.id === "shareCustomize" && !nameFromUrl && (
                <Popover
                  id={idClickedIconPopOver}
                  open={openSave}
                  anchorEl={saveGlbStateElement}
                  onClose={handleCloseSavePopOver}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  about="saveGlbPopOver"
                >
                  <form className="formSave" onSubmit={handleSubmitSaveGlb} autoComplete="off">
                    <TextField id="nameEdit" name="name" placeholder="Name this design" onChange={(e) => dispatch(setNameEdit(e.target.value))} value={nameEdit} aria-describedby="white-form" />
                    <Button type="submit" className="btnPOpOverSave" about="popOverBtn" variant="contained" color="primary">
                      <img src={save} alt="icon" className="imgIcon" />
                    </Button>
                  </form>
                </Popover>
              )}
            </>
          ))}
        </div>
      </div>
      {buyProduct && <OrderProduct handleClose={() => { setBuyProduct(false) }} />}
      <div className="buttons">
        <div className="create" role="button" onClick={() => { setBuyProduct(true) }}>
          <div className="contain"></div>
          <img src="/images/shopping-bag.svg" alt="bag" className="img" />
          <div className="letters"> Order One Product</div>
        </div>
        <div className="create" role="button">
          <div className="contain"></div>
          <img src="/images/blockchain.png" alt="blockchain" className="img" />
          <div className="letters"> Create a collection (CS)</div>
        </div>
      </div>
    </div>
  );
}
