import { useLazyQuery, useMutation } from "@apollo/client";
import { useState, useRef } from "react";
import { INSERT_GLB } from "../../../../graphql/addGlb.mutation";
import { useFileUpload } from "../../../../hooks/UseFileUpload";
import { APP_CONTAINER_CLASS } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { appRoutes } from "../../../../utils/appRoutes";
import { SINGLE_GLB_INFO } from "../../../../graphql/singleGlbInfo.query";
import { UPDATE_GLB } from "../../../../graphql/updateGlb.mutation";
import { useDispatch } from "react-redux";

export const useGlbUpload = (edit) => {
  const [insertGlb, { data, loading }] = useMutation(INSERT_GLB);
  const {
    loading: uploadGlbLoading,
    data: uploadGlbData,
    handleFileUplaod: uploadGlb,
  } = useFileUpload(true);
  const {
    loading: uploadImageLoading,
    data: uploadImageData,
    handleFileUplaod: uploadImage,
  } = useFileUpload(true);
  const {
    loading: deleteGlbLaoding,
    data: deleteGlbDataState,
    handleFileDelete: deleteGlb,
  } = useFileUpload(true);
  const {
    loading: deletePlaceholderLaoding,
    data: deletePlaceholderDataState,
    handleFileDelete: deletePlaceholder,
  } = useFileUpload(true);
  const { id } = useParams();
  const [glbInformation, { data: glbData, loading: glbLoading }] = useLazyQuery(
    SINGLE_GLB_INFO,
    { fetchPolicy: "no-cache" }
  );
  const [updateGlb, { loading: updateGlbLoading, data: updateGlbData }] =
    useMutation(UPDATE_GLB);
  const [hookError, setHookError] = useState(null);

  const navigate = useNavigate();
  const requestRef = useRef();

  // handle step finalization
  const dispatch = useDispatch();

  const fileHandling = async (glbFile, placeholderImage, glbInfo) => {
    const handleGlbFile = async () => {
      if (edit) {
        if (typeof glbFile === "string") {
          return {
            key: glbFile.split(process.env.REACT_APP_CLOUDFRONT_URL).pop(),
            url: glbFile,
          };
        }
        await deleteGlb(glbInfo.file_key);
        return await uploadGlb(glbFile, "glb");
      }
      return await uploadGlb(glbFile, "glb");
    };

    const handleplaceholderImage = async () => {
      if (edit) {
        if (typeof placeholderImage === "string") {
          return {
            key: placeholderImage
              .split(process.env.REACT_APP_CLOUDFRONT_URL)
              .pop(),
            url: placeholderImage,
          };
        }
        await deletePlaceholder(glbInfo.file_key);
        return await uploadImage(placeholderImage, "texture");
      }
      return await uploadImage(placeholderImage, "texture");
    };

    const glbFileUrl = await handleGlbFile();
    const placeholdereUrl = await handleplaceholderImage();
    return { glbFileUrl, placeholdereUrl };
  };
  const handleGlbUpload = async (data) => {
    try {
      if (requestRef.current) return null;
      requestRef.current = true;
      document
        .querySelector(APP_CONTAINER_CLASS)
        .scrollTo({ top: 0, behavior: "smooth" });
      setHookError(null);
      const {
        glbFile,
        placeholderImage,
        productSku,
        glbName,
        isPrivate,
        category,
      } = data;
      const glbCurrentInfo =
        edit && (await glbInformation({ variables: { id } }));
      const { glbFileUrl, placeholdereUrl } = await fileHandling(
        glbFile,
        placeholderImage,
        edit && glbCurrentInfo?.data?.users_glbs_by_pk
      );
      if (glbFileUrl && placeholdereUrl) {
        let res;
        if (edit) {
          res = await updateGlb({
            variables: {
              category,
              SKU: productSku,
              name: glbName,
              private: isPrivate,
              file_key: glbFileUrl.key,
              glb_file_url: glbFileUrl.url,
              placeholder_image_url: placeholdereUrl.url,
              placeholder_key: placeholdereUrl.key,
              id,
            },
          });
        } else {
          res = await insertGlb({
            variables: {
              category,
              SKU: productSku,
              name: glbName,
              private: isPrivate,
              file_key: glbFileUrl.key,
              glb_file_url: glbFileUrl.url,
              placeholder_image_url: placeholdereUrl.url,
              placeholder_key: placeholdereUrl.key,
            },
          });
        }
        navigate(
          appRoutes.uploadGlb+appRoutes.uploadGlbSubRoutes.selectProductFunc(
            res.data?.insert_users_glbs_one?.id ||
              res.data?.update_users_glbs_by_pk?.id
          )
        );
        requestRef.current = false;
      }
    } catch (error) {
      console.log(error);
      requestRef.current = false;
      setHookError("an error occured when upload the glb file");
    }
  };

  const handleLoadingMessage = () => {
    if (loading) {
      return "Creating glb file";
    }
    if (uploadGlbLoading) {
      return "Uploading glb file";
    }
    if (uploadImageLoading) {
      return "Uploading placeholder image";
    }
    if (deleteGlbLaoding) {
      return "Deleting old glb file";
    }
    if (deletePlaceholderLaoding) {
      return "Deleting old placeholder image";
    }
    if (updateGlbLoading) {
      return "Updating glb info";
    }
    if (glbLoading) {
      return "Loading glb info";
    }
  };

  const loadingState = handleLoadingMessage();
  const errorState = hookError;
  const dataState =
    data &&
    uploadGlbData &&
    uploadImageData &&
    deleteGlbDataState &&
    deletePlaceholderDataState &&
    updateGlbData &&
    glbData;

  return { loadingState, errorState, dataState, handleGlbUpload };
};
