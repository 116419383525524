import { useSubscription } from '@apollo/client';
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DID_PAYMENT_SUCEED } from '../../graphql/didPaymentSuceed.subscription';
import { appRoutes } from '../../utils/appRoutes';

export default function PaymentValidation() {
    let { search } = useLocation();
    const { id } = useParams()
    const query = new URLSearchParams(search);
    const paymentLink = query.get('paymentLink');
    const { data } = useSubscription(DID_PAYMENT_SUCEED, { variables: { cart_id: id } })
    const navigate = useNavigate()
    return (
        <>
            {data && data.payments[0].is_verified ? navigate(appRoutes.orders) : null}
            <iframe src={paymentLink} frameborder="0" style={{ width: "100vw", height: "100vh" }} title='payment'></iframe>
        </>
    )
}
