import React from "react";
import styles from "./RightPageDetails.module.scss";

export default function RightPageDetailsNoLayout({ children, title, subTitle }) {
    return (
        <div className={styles.RightPageDetails}>
            <div className="upload">
                <div className="top_content">
                    <div className="title">{title}</div>
                    <div className="sub_title">{subTitle}</div>
                </div>
                <div className="uploaded_glb">
                    <div className="the-container">{children}</div>
                </div>
            </div>
        </div>
    );
}
