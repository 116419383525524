import { createTheme } from "@mui/material/styles";

const xlg = "320px";
const lg = "425px";
const md = "600px";
const xls = "500px";
const sm = "400px";

const mq = (size, styles) => {
  return {
    [`@media (max-width: ${size})`]: styles,
  };
};

export const defaultTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000 !important",
      light: "red !important",
    },
    secondary: {
      main: "#00000080 !important",
    },
    warning: {
      main: "#FFE347 !important",
    },
    error: {
      main: "#AB4E68  !important",
      light: "#A7687A  !important",
      dark: "#AD3355  !important",
    },
    info: {
      main: "#C4FF03  !important",
      light: "#C4FF03 !important",
      dark: "#C4FF03 !important",
    },
    success: {
      main: "#4ED326  !important",
      light: "#7AC962 !important",
      dark: "#2D970D !important",
    },
    danger: {
      main: "#C4FF03 !important",
    },
    dark: {
      main: "#000000 !important",
    },
    light: {
      main: "#ffffff !important",
    },
    grey: {
      main: "red !important",
    },
  },
  typography: {
    styleOverrides: {
      root: {
        fontFamily: `sfProDisplayMedium`,
      },
    },
    h1: {
      fontSize: "28.37px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "24px",
      }),
    },
    h2: {
      fontSize: "25.22px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "29px",
      }),
    },
    h3: {
      fontSize: "22.42px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "19px",
      }),
    },
    h4: {
      fontSize: "19.93px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "17px",
      }),
      ...mq(md, {
        fontSize: "16px",
      }),
      ...mq(xls, {
        fontSize: "14px",
      }),
    },
    h5: {
      fontSize: "17.72px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "16px",
      }),
    },
    h6: {
      fontSize: "16px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff",
      ...mq(lg, {
        fontSize: "14px",
      }),
      ...mq(xls, {
        fontSize: "12px",
      }),
      ...mq(sm, {
        fontSize: "10px",
      }),
    },
    body2: {
      fontSize: "14px",
      fontFamily: `sfProDisplayMedium`,
      color: "#fff !important",
      ...mq(lg, {
        fontSize: "12px",
      }),
      ...mq(xls, {
        fontSize: "10px",
      }),
      ...mq(xls, {
        fontSize: "8px",
      }),
      variants: [
        {
          props: { about: "center" },
          style: {
            display: "flex",
            "& .css-1iqhs94-MuiTypography-root": {
              textAlign: "center",
            },
          },
        },
      ],
    },
    button: {
      fontSize: "16px",
      fontFamily: `sfProDisplayMedium`,
      ...mq(lg, {
        fontSize: "14px",
      }),
      ...mq(xls, {
        fontSize: "12px",
      }),
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { color: "red" },
          style: {
            color: "#EB5D5D !important",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          placeItems: "center",
          borderRadius: "0px",
          textTransform: "none ",
          boxShadow: "none",
          textAlign: "center",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#4D4D4D !important",
          },
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            height: "40px",
          },
        },
        {
          props: { about: "popOverBtn" },
          style: {
            borderRadius: "0px !important",
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 !important",
            minWidth: "56px",
            maxWidth: "56px",
            margin: 0,
            ...mq(xlg, {
              height: "40px",
              minWidth: "40px",
              maxWidth: "40px",
            }),
            ...mq(md, {
              height: "38px",
              minWidth: "38px",
              maxWidth: "38px",
            }),
            "& .css-1273ajo-MuiButtonBase-root-MuiButton-root": {
              minWidth: "100%",
              maxWidth: "100%",
            },
          },
        },
        {
          props: { color: "success" },
          style: {
            color: "white",
            backgroundColor: "#C4FF03",
          },
        },
        {
          props: { about: "purple" },
          style: {
            color: "#EB5D5D",
            backgroundColor: "#EB5D5D",
            ...mq(lg, {
              fontSize: "10px",
            }),
            transition: "all 0.3 ease-in-out",
            "&:hover": {
              backgroundColor: "#FF6565",
            },
          },
        },
        {
          props: { about: "light" },
          style: {
            color: "black",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: "#E3E3E3 !important",
            },
          },
        },
        {
          props: { about: "cancel" },
          style: {
            color: "white",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#524f4f !important",
            },
          },
        },
        {
          props: { about: "buy" },
          style: {
            color: "white !important",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: "#C4FF03",
            "&:hover": {
              backgroundColor: "#6AFF73 !important",
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff !important",
          borderRadius: "0px !important",
          paddingLeft: "30px",
          border: "none",
          "& input": {
            fontFamily: "sfProDisplayMedium",
          },
          "& fieldset": {
            border: "none",
          },
        },
      },
    },
    MuiPopover: {
      variants: [
        {
          props: { about: "saveGlbPopOver" },
          style: {
            "& .MuiPaper-root": {
              height: "56px",
            },
          },
        },
        {
          props: { about: "muiPaperPopover" },
          style: {
            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
              borderRadius: "0px",
              backgroundColor: "transparent !important",
              ...mq(xlg, {
                borderRadius: "0px",
              }),
              ...mq(lg, {
                borderRadius: "0px",
              }),
              ...mq(md, {
                borderRadius: "0px",
              }),
            },
          },
        },
        {
          props: { about: "popOverWithInput" },
          style: {
            overflow: "hidden",
            padding: 0,
            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
              borderRadius: "0px",
              width: "400px",
              padding: "8px",
              mixBlendMode: "color-dodge",
              backdropFilter: "blur(34px)",
              webkitBackdropFilter: "blur(34px)",
              backgroundColor: "rgba(255, 255, 255, .5) !important",
              background: "transparent",
              ...mq(xlg, {
                width: "300px",
              }),
              ...mq(lg, {
                width: "280px",
              }),
              ...mq(xls, {
                width: "250px",
              }),
              ...mq(sm, {
                width: "200px",
              }),
              "& .formSave": {
                width: "100%",
                maxHeight: "45px",
                display: "flex",
                padding: 0,
                alignItems: "center",
                gap: "15px",
                background: "transparent",
                ...mq(xlg, {
                  maxHeight: "40px",
                  gap: "10px",
                }),
                ...mq(md, {
                  maxHeight: "38px",
                  gap: "8px",
                }),
              },
            },
          },
        },
        {
          props: { about: "blurred" },
          style: {
            "& .MuiPaper-root": {
              backdropFilter: "blur(20px)",
              background: "rgba(255,255,255,0.2) !important",
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      variants: [
        {
          props: { title: "checkout" },
          style: {
            padding: "0px !important",
            "& .MuiSvgIcon-root": {
              "& path": {
                fill: "#fff",
                stroke: "#fff",
              },
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          textTransform: "none ",
          boxShadow: "none",
          textAlign: "center",
          maxHeight: "50px",
          "&:hover": {
            boxShadow: "none",
          },
          ...mq(xlg, {
            maxHeight: "40px",
          }),
          ...mq(md, {
            maxHeight: "35px",
          }),
          "& .MuiOutlinedInput-root": {
            backgroundColor: "black !important",
            color: "white  !important",
          },
        },
      },
      variants: [
        {
          props: { about: "checkout" },
          style: {
            backgroundColor: "#24292e",
            width: "100%",
            "& -webkit-autofill": {
              "-webkit-text-fill-color": "yellow !important",
            },
            "& label": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              backgroundColor: "#24292e !important",
              border: "none !important",
              paddingLeft: "29px",
              marginTop: "10px",
              marginBottom: "10px",
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
              "& input": {
                fontSize: "15px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 1)",
              },
            },
            "& .MuiFormHelperText-root": {
              color: "#EB5D5D",
            },
          },
        },
        {
          props: { about: "official-form" },
          style: {
            backgroundColor: "#000",
            width: "100%",
            "& -webkit-autofill": {
              "-webkit-text-fill-color": "yellow !important",
            },
            "& label": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              backgroundColor: "#000 !important",
              border: "none !important",
              paddingLeft: "29px",
              marginTop: "10px",
              marginBottom: "10px",
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
              "& input": {
                fontSize: "15px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 1)",
              },
            },
            "& .MuiFormHelperText-root": {
              color: "#EB5D5D",
            },
          },
        },
        {
          props: { "aria-describedby": "white-form" },
          style: {
            backgroundColor: "white !important",
            color: "black !important",
            padding: "0px",
            "& .MuiInputBase-root": {
              padding: "0px",
            },
            "& input": {
              backgroundColor: "white !important",
              color: "black !important",
            },
          },
        },
        {
          props: { size: "search" },
          style: {
            backgroundColor: "white",
            "& .MuiInputBase-root": {
              backgroundColor: "black !important",
              borderRadius: "0px !important",
            },
            "& .css-1rvql17-MuiInputBase-root-MuiFilledInput-root": {
              backgroundColor: "black !important",
              borderRadius: "0px !important",
            },
          },
        },
        {
          props: { about: "small" },
          style: {
            backgroundColor: "white",
            "& input": {
              padding: "0 0px 0px 20px",
            },
            "& input::placeholder": {
              color: "#fff",
              opacity: 0.5,
            },
          },
        },
        {
          props: { about: "popOpverInput" },
          style: {
            backgroundColor: "white",
            flexGrow: 1,
            borderRadius: "0px",
            height: "45px",
            padding: 0,
            display: "flex",
            ...mq(xlg, {
              height: "40px",
            }),
            ...mq(md, {
              height: "38px",
            }),
            "& input": {
              padding: "0 5px 0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              borderRadius: "0px",
              height: "45px",
              fontSize: ".8rem",
              background: "white",
              ...mq(xlg, {
                height: "40px",
              }),
              ...mq(md, {
                height: "38px",
              }),
            },
            "& input::placeholder": {
              color: "rgba(250,250,250,.7)",
              fontSize: ".7rem",
            },
            "& .css-1uk2hkq-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
              borderRadius: "inherit",
              padding: 0,
              margin: 0,
            },
          },
        },
        {
          props: { about: "loginput" },
          style: {
            backgroundColor: "#000 !important",
            borderRadius: "0px",
            height: "45px",
            flexGrow: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "space-between",
            ...mq(xlg, {
              height: "40px",
            }),
            ...mq(md, {
              height: "35px",
            }),
            ...mq(xls, {
              height: "30px",
            }),
            "& .MuiInputBase-root": {
              backgroundColor: "#000 !important",
            },
            "& input": {
              padding: "0px",
              backgroundColor: "#000 !important",
            },
            "& .css-1lkr5rz-MuiInputBase-input-MuiOutlinedInput-input, .css-7053f3-MuiInputBase-input-MuiOutlinedInput-input":
              {
                color: "rgba(250,250,250)",
                "&::placeholder": {
                  fontSize: "16px",
                  ...mq(md, {
                    fontSize: "12px",
                  }),
                  color: "rgba(250, 250, 250, 0.6)",
                },
              },
            "& .css-7053f3-MuiInputBase-input-MuiOutlinedInput-input": {
              "&::placeholder": {
                fontSize: "18px",
                ...mq(md, {
                  fontSize: "16px",
                }),
              },
            },
            "& .css-18us4qe-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
            },
            "& .css-1uk2hkq-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& label": {
            color: "white",
            opacity: 0.5,
            fontFamily: "sfProDisplayMedium",
            paddingLeft:"30px"
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          minHeight: "unset",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          paddingTop: "10px",
          paddingBottom: "10px",
          "& .Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#070082",
            "&:hover": {
              backgroundColor: "#332BC8",
            },
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            },
          },
          "&:hover": {
            backgroundColor: "#070082",
            color: "#ffffff",
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            },
          },
        },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { about: "uploadglb" },
          style: {
            "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              borderRadius: "0px !important",
              backgroundColor: "#24292E !important",
            },
            "& .css-qfso29-MuiTypography-root-MuiDialogContentText-root": {
              color: "black",
              textAlign: "left",
            },
            "& .css-draqkd-MuiTypography-root-MuiDialogTitle-root": {
              textAlign: "left",
            },
          },
        },
        {
          props: { about: "preview" },
          style: {
            "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              borderRadius: "0px !important",
              backgroundColor: "#24292E !important",
              minHeight: "60vh",
              maxHeight: "fit-content",
              minWidth: "40vw",
              padding: "10px",
              overFlow: "hidden",
            },
            "& .css-qfso29-MuiTypography-root-MuiDialogContentText-root": {
              color: "black",
              textAlign: "left",
            },
            "& .css-draqkd-MuiTypography-root-MuiDialogTitle-root": {
              textAlign: "left",
            },
            "& .css-hlj6pa-MuiDialogActions-root": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            "& .css-161g7ga-MuiDialogContent-root": {
              borderRadius: "0px",
              backgroundColor: "white",
              overFlow: "hidden",
            },
            "& .css-1j861ql": {
              width: "100%",
              minHeight: "60vh",
              margin: 0,
            },
            "& .css-1wmld2k-MuiPaper-root-MuiAlert-root": {
              display: "flex",
              alignItems: "center",
              ...mq(xlg, {
                fontSize: "12px",
              }),
              ...mq(lg, {
                fontSize: "11px",
              }),
              ...mq(md, {
                fontSize: "10px",
              }),
              ...mq(xls, {
                fontSize: "8px",
              }),
            },
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.5em",
          lineHeight: `${31.2 / 24}`,
          textAlign: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: "1em",
          lineHeight: 1.3,
          textAlign: "center",
          fontFamily: `sfProDisplayMedium`,
          color: "#000000",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
          marginRight: "0px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: "black",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { severity: "error" },
          style: {
            background: "#E45C76 !important",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { severity: "info" },
          style: {
            background: "#A49BD0",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { severity: "success" },
          style: {
            background: "#C4FF03",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { about: "fill-width" },
          style: {
            width: "100%",
          },
        },
      ],
    },
    MuiSkeleton: {
      variants: [
        {
          props: { about: "rounded" },
          style: {
            width: "100%",
            borderRadius: "0px",
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "black !important",
          color: "white",
          borderRadius: "0px",
          "& .MuiSelect-icon": {
            color: "white",
          },
          "& .MuiSelect-select": {
            color: "white",
          },
        },
      },
      variants: [
        {
          props: { about: "white" },
          style: {
            padding: "0px !important",
            color: "black !important",
            border: "solid 2px black !important",
            backgroundColor: "white !important",
            fontSize: "14px",
            fontFamily: "sfProDisplayMedium",
            "& .MuiSelect-icon": {
              color: "black",
            },
            "& .MuiSelect-select": {
              color: "black",
            },
          },
        },
        {
          props: { about: "blurred" },
          style: {
            padding: "0px !important",
            fontFamily: "sfProDisplayMedium",
            color: "white !important",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255,255,255,0.2) !important",
            fontSize: "14px",
            "& .MuiSelect-icon": {
              color: "white",
            },
            "& .MuiSelect-select": {
              color: "white",
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#24292E !important",
          color: "white",
          borderRadius: "0px",
        },
      },
    },
    MuiFormLabel: {
      variants: [
        {
          props: { about: "black" },
          style: {
            fontFamily: "sfProDisplayMedium",
            fontSize: "1em",
            fontWeight: "900",
          },
        },
        {
          props: { about: "white" },
          style: {
            fontFamily: "sfProDisplayMedium",
            fontSize: "1em",
            fontWeight: "900",
            color: "white !important",
          },
        },
      ],
    },
  },
  spacing: 8,
});
