import { createSlice } from "@reduxjs/toolkit";

export const GlbUploadPageSlice = createSlice({
  name: "mainPageSlice",
  initialState: {
    glbUploadBlob: null
  },
  reducers: {
    setGlbUploadBlob: (state, action) => {
      state.glbUploadBlob = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setGlbUploadBlob
} = GlbUploadPageSlice.actions;

export const glbUploadReducer = GlbUploadPageSlice.reducer;
