import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleClose } from '../../../../redux/cart/cartSlice'
import { Popover, Typography } from '@mui/material'
import styles from "./CartPopOver.module.scss"
import { Cart } from '../../svgs/Cart'
import SingleCartItem from '../singleCartItem/SingleCartItem'
import CartCalculation from '../CartCalculation/CartCalculation'

export default function CartPopOver() {
    const cartOpen = useSelector((state) => state.cartSliceReducer.cartOpen)
    const anchorEl = useSelector((state) => state.cartSliceReducer.anchorEl)
    const cartItems = useSelector((state) => state.cartSliceReducer.cartItems)
    const dispatch = useDispatch()
    const handleClosePopOver = () => {
        dispatch(handleClose())
    }
    return (
        <Popover
            open={cartOpen}
            anchorEl={anchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div id={styles.CartPopOver}>
                <div className="header">
                    <div className='h-flex'>
                        <img src="/images/close_cart.svg" alt="" style={{ cursor: "pointer" }} onClick={handleClosePopOver} />
                        <div className="cart-text">Shopping cart</div>
                        <Cart />
                    </div>
                </div>
                {cartItems.length === 0 && (<>
                    <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
                        <img src="/images/empty_cart.svg" style={{ width: "70%", height: "auto" }} alt="empty cart" />
                        <Typography variant='h3' sx={{ marginTop: "20px", opacity: "0.8",marginBottom:"32px" }}>No Item yet added to cart</Typography>
                    </div>
                </>)}   
                {cartItems.length > 0 && <><div className="items">{cartItems.length} {cartItems.length <= 1 ? "item" : "items"}</div>
                    <div className="cart-items">
                        {cartItems.map((item, idx) => {
                            return <SingleCartItem item={item} key={idx} />
                        })}

                    </div>
                    <CartCalculation></CartCalculation></>}
            </div>
        </Popover>
    )
}
