import { useContext } from "react"
import { PRODUCT_DETAILS_CONTEXT } from "./productDetails"

export const SelectSizes = () => {
    const { selectedSize, setSelectedSize, data } = useContext(PRODUCT_DETAILS_CONTEXT)
    const sizes = () => {
        const attributes = data.attributes
        for (let i = 0; i < attributes.length; i++) {
            const item = attributes[i];
            if (item.name === "size") {
                return item.values.map((item) => {
                    return item.name
                })
            }
        }
    }
    return (<>
        <div className="select-size">
            <div className="item-title">Select Size</div>
            <div className="list-of-sizes">
                {sizes().map((item, idx) => {
                    return (<div className={`single-size ${selectedSize === item && "active-size" }`} key={idx} onClick={()=>setSelectedSize(item)}>{item}</div>)
                })}
            </div>
        </div>
    </>)
}