import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { handleTextureMove } from "../configurator/events/handleTextureMoving";
import { handleUV } from "../configurator/events/renderUv";
import { homeContext } from "../../Home";
import styles from "./EditTexturePopUp.module.scss";
import CanvasComponent from "./FabricJsEditor";

export default function EditTexturePopUp({ setShowEditbiggerPopUp, texture }) {
  const [offSetX, setOffSetX] = useState(0);
  const [offSetY, setOffSetY] = useState(0);
  const [repeatX, setRepeatX] = useState(1);
  const [repeatY, setRepeatY] = useState(1);

  const { uvCanvasRef } = useContext(homeContext);

  const textureProperty = { offSetX, offSetY, repeatX, repeatY };

  const handleoffSetX = (e) => setOffSetX(e.target.value);
  const handleoffSetY = (e) => setOffSetY(e.target.value);
  const handleRepeatX = (e) => setRepeatX(e.target.value);
  const handleRepeatY = (e) => setRepeatY(e.target.value);
  console.log("the texture", texture);
  useEffect(() => {
    handleUV();
  }, []);

  useEffect(() => {
    handleTextureMove(textureProperty);
  }, [textureProperty]);

  return (
    <div id={styles.editTexturePopUp}>
      <div className="child">
        <div className="PopUpHeader">
          <div className="words">
            <div className="title">Place Image Where You Need It</div>
            <div className="subtitle">Drag image around,scale it move it to get you desired effect</div>
          </div>
          <div className="closeBox">
            <img src="/images/closeIcon.svg" alt="close btn" className="closeBtn" onClick={() => setShowEditbiggerPopUp(false)} />
          </div>
        </div>
        <CanvasComponent />
      </div>
    </div>
  );
}
