import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./sass/fonts.scss";

import store from "./redux/store";
import { Provider } from "react-redux";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { QueryClient, QueryClientProvider } from "react-query";
import { setContext } from "@apollo/client/link/context";

import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const token = localStorage.getItem("token")
  ? `Bearer ${localStorage.getItem("token")}`
  : null;

const handleTheAuth = () => {
  if (token) {
    return {
      uri: `${process.env.REACT_APP_HASURA_URL}`,
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    };
  } else {
    return {
      uri: `${process.env.REACT_APP_HASURA_URL}`,
    };
  }
};
const httpLink = new HttpLink({
  ...handleTheAuth(),
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_HASURA_WSS_URL}`,
    connectionParams: {
      headers: {
        authorization: token,
      },
      lazy: true,
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  const includeToken = () => {
    if (token) {
      return { authorization: `Bearer ${token}` };
    } else {
      return null;
    }
  };
  return {
    headers: {
      ...headers,
      ...includeToken(),
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(splitLink),
});

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://6e427b508772d676a8c56a3d307fb440@o4506082266906624.ingest.us.sentry.io/4508054018392064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
