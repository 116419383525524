import { Alert, LinearProgress } from "@mui/material";
import { useQuery } from "react-query";
import { apiRoutes, authetictedAxios } from "../../utils/api";
import { useParams } from "react-router-dom";
export function OrderDetails() {
    const {id}=useParams()
    const { isLoading, data, isError } = useQuery(apiRoutes.getCartInfoFunc(id),async()=>{
        const res=await authetictedAxios().get(apiRoutes.getCartInfoFunc(id))
        if(res.data){
            return res.data
        }
    },{
        refetchOnMount:false,
        refetchOnReconnect:false,
        refetchOnWindowFocus:false,
        cacheTime:0
    })
    return (
        <>
            {isLoading && <LinearProgress color="info" /> }
            {isError && <Alert severity="error" color="error">There was an error fetching cart detials</Alert>}
            {data && <div className="part-2">
                <div className="checkout-cart">
                    <div className="title-head">YOUR ORDER</div>
                    <table>
                        <tr>
                            <td>Product</td>
                            <td>Size</td>
                            <td>Items</td>
                            <td>Cost</td>
                        </tr>
                        <tbody>
                            {console.log("")}
                            {data && data.cartItems.map((item)=>{
                                return <tr>
                                    <td>{item.item.user_moded_glb.name}</td>
                                    <td>{item.item.variant}</td>
                                    <td>{item.item.count}</td>
                                    <td>{item.item.count * item.merchizeInfo.retail_price}{" "}{data && data.currency}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <hr></hr>
                    <div className="final-cost">
                        <div>Subtotal</div>
                        <div>{data && data.total}{" "}{data && data.currency}</div>
                    </div>
                    <div className="final-cost">
                        <div>Shipping</div>
                        <div>Free shipping</div>
                    </div>
                    <div className="final-cost">
                        <div>Total</div>
                        <div>{data && data.total}{" "}{data && data.currency}</div>
                    </div>
                    <div className="bottom-el">
                        <label>Your personal data will be used to process your order,
                            support your experience throughout this website, and for
                            other purposes described in our privacy policy.</label>
                        <div>
                            <button className="place-order" type="submit">PLACE ORDER</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}