import React, { createContext, useEffect, useState } from 'react'
import Steps from '../../Steps'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../utils/appRoutes'
import RightPageDetailsNoLayout from '../../../../layout/RightPageDetailsNoLayout.layout'
import { Alert, Backdrop, Button, CircularProgress, Grid, LinearProgress, Snackbar } from '@mui/material'
import SingleMatch from './SingleMatch'
import { useQuery } from 'react-query'
import { useQuery as useApolloQuery } from "@apollo/client"
import { apiRoutes, authetictedAxios } from '../../../../utils/api'
import { useMutation } from '@apollo/client'
import { UPDATE_GLB_MATCHING_FOR_GLB } from '../../../../graphql/updateGlbMatchingForGlb.mutation'
import { CURRENT_MATCH } from '../../../../graphql/doWeHaveLinkedProduct.query'

export const MatchContext = createContext()

export default function MatchGlb() {
    const navigate = useNavigate()
    const { id } = useParams()

    const { loading: loadingCurrent, data: currentData, error: currentError } = useApolloQuery(CURRENT_MATCH, { variables: { id } })
    const handleBack = () => {
        navigate(appRoutes.uploadGlb + appRoutes.uploadGlbSubRoutes.selectProductFunc(id))
    }
    const handleNext = () => {
        navigate(appRoutes.manageGlb)
    }

    const { isLoading, data, isError } = useQuery(apiRoutes.matchGlbInfo + id, async () => {
        const res = await authetictedAxios().post(apiRoutes.matchGlbInfo, {
            id
        })
        if (res) {
            return res.data
        }
    }, {
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: -1,
        cacheTime: 0,
        staleTime: 0
    })

    const [match, setMatch] = useState({})
    const [snackError, setSnackError] = useState(null)

    // set match 
    useEffect(() => {
        if (currentData) {
            if (currentData.users_glbs_by_pk.pattern_match) {
                setMatch(currentData.users_glbs_by_pk.pattern_match)
            }
        }
    }, [currentData])


    const validatePatternMatch = () => {
        const uniquePattern = {}
        const keys = Object.keys(match)
        for (let i = 0; i < keys.length; i++) {
            const item = match[keys[i]];
            uniquePattern[item] = keys[i]
        }
        if (Object.keys(uniquePattern).length === data.users_glbs_by_pk.merchize_product.glb_matchings.length) {
            return true
        }
        return false
    }

    const [updateMatch, { loading, error }] = useMutation(UPDATE_GLB_MATCHING_FOR_GLB)
    const handlePatternMatchUpdate = async () => {
        if (validatePatternMatch()) {
            const res = await updateMatch({
                variables: {
                    id,
                    match_obj: match
                }
            })
            if (res.data.update_users_glbs_by_pk.pattern_match) {
                navigate(appRoutes.manageGlb)
            }
            else {
                setSnackError("There was an error updating the glb pattern")
            }
        }
        setSnackError("Please match all available pattern to proceed")
    }
    return (
        <>
            <Steps activeStep={2} handleBack={handleBack} handleNext={handleNext} />
            <MatchContext.Provider value={{ match, setMatch }}>
                <RightPageDetailsNoLayout title="Match Product  And Pattern" subTitle="Link Glb Section to it corresponding glb">
                    {(isLoading || loadingCurrent) && <LinearProgress color="info" sx={{ marginBottom: "20px" }}></LinearProgress>}
                    {currentError && <Alert severity='error' sx={{ marginBottom: "20px" }}>There was an error loading current errors</Alert>}
                    {isError && <Alert severity='error' sx={{ marginBottom: "20px" }}>There was an error getting the single pattern and glb info</Alert>}
                    {data && currentData && (
                        <>
                            <Grid container spacing={2}>
                                {data.users_glbs_by_pk.merchize_product.glb_materials.map((item) => {
                                    return (
                                        <Grid item xs={6}>
                                            <SingleMatch glb_url={item.glb_url} patterns={data.users_glbs_by_pk.merchize_product.glb_matchings} key={item.glb_url} glb_id={item.id}></SingleMatch>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                {snackError && <Snackbar
                                    open={true}
                                    autoHideDuration={6000}
                                    onClose={() => setSnackError(null)}
                                    message="Note archived"
                                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                                >
                                    <Alert severity='error' >{snackError}</Alert>
                                </Snackbar>}
                                {loading && <Backdrop><CircularProgress color='info'></CircularProgress></Backdrop>}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    value={false}
                                    onClick={handlePatternMatchUpdate}
                                    sx={{
                                        width: "25%",
                                        height: "60px",
                                        background: "white",
                                        marginTop: "32px"
                                    }}
                                >
                                    Publish Final Glb
                                </Button>
                            </div>
                        </>
                    )
                    }
                </RightPageDetailsNoLayout>
            </MatchContext.Provider>
        </>
    )
}
