import { configureStore } from "@reduxjs/toolkit";
import mainPageSliceReducer from "./mainPageSlice";
import { glbUploadReducer } from "../pages/UploadGlbPage/UploadGlbPage.slice";
import detailsSlice from "../pages/Home/components/StableDiffusion/autoGenerated.slice";
import inputFieldSlicesReducers from "../pages/Home/components/PageManIconPart/fieldInput.slice";
import headerReducers from "../components/topNav/topNav.slice";
import cartSlice from "./cart/cartSlice";

export default configureStore({
  reducer: {
    mainPageSliceReducer: mainPageSliceReducer,
    glbUploadReducer,
    imageDetailsReducer: detailsSlice,
    inputFieldSlicesReducers,
    cartSliceReducer: cartSlice,
    headerReducers,
  },
});
