import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import CardBlurPopup from "../CardBlurPopup/CardBlurPopup";
import { useContext, useRef } from "react";
import { Popover } from "@mui/material";
import UpdateTexturePopUp from "./components/updateTexturePopUp/updateTexturePopUp";
import { STABLE_DIFFUSION_CONTEXT } from "./StableDiffusion";

export function SingleGeneratedImage({ card }) {
    const link = useRef(null);
    const { showSmallPopup, cardId, handleClickOutsideCard, handleClickedCard, handleClickOpenPopOver, id,  anchorEl, handleClosePopOver, open } = useContext(STABLE_DIFFUSION_CONTEXT)
    return (
        <div key={card.id} className="card" >
            {showSmallPopup && cardId === card.id && (
                <OutsideClickHandler onOutsideClick={handleClickOutsideCard}>
                    <CardBlurPopup id={card.id} texture={card.url} />
                </OutsideClickHandler>
            )}
            <div className="btns_container" style={{ backgroundImage: `url("${card.url}")` }}>
                <div className="actions_top">
                    <a href={card.url} target="_blank" rel="noopener noreferrer" ref={link} hidden></a>
                    <div className="btn" onClick={() => link.current.click()}>
                        Download Image
                        <div className="btnLogo">
                            <img src="/images/downloadGeneration.svg" alt="edit Icon" className="editIcon" />
                        </div>
                    </div>
                </div>
                <div className="clickableSeciton" onClick={() => handleClickedCard(card.id, card.image)}></div>
                <div className="actions_bottom">
                    {card.id && <div className="number">{card.id < 10 ? `0${card.id}` : card.id} </div>}
                    <div className="btn" onClick={(e) => handleClickOpenPopOver(e, card.id)}>
                        Edit Prompt
                        <div className="btnLogo">
                            <img src="/images/edit-2-autlined.svg" alt="edit Icon" className="editIcon" />
                        </div>
                    </div>
                    {cardId === card.id && (
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopOver}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            className="updatePopup"
                            about="blurred"
                        >
                            <UpdateTexturePopUp content={card.prompt} style={card.style} />
                        </Popover>
                    )}
                </div>
            </div>
        </div>
    );
}