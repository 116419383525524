import { gql } from "@apollo/client";

export const INSERT_GAME_MUTATION = gql`
  mutation INSERT_GAME_MUTATION(
    $glb_key: String!
    $glb_name: String!
    $glb_url: String!
    $sdk_game_id: uuid!
  ) {
    insert_sdk_game_glbs_one(
      object: {
        glb_key: $glb_key
        glb_name: $glb_name
        glb_url: $glb_url
        sdk_game_id: $sdk_game_id
      }
    ) {
      id
    }
  }
`;
