export const appRoutes = {
  home: "/:id",
  payment: "/payment/:id",
  paymentFunc: (id, paymentLink) => `/payment/${id}?paymentLink=${paymentLink}`,
  listOfProducts: "/listOfProducts",
  editProduct: "/editproduct",
  login: "/login",
  signup: "/signup",
  signupWithUserType: "/signup/:id",
  confirmEmail: "/auth/confirmation/:token",
  uploadGlb: "/upload-glb/",
  uploadGlbSubRoutes: {
    uploadGlb: "",
    edit: ":id",
    selectProduct: "selectProduct/:id",
    selectProductFunc: (id) => `selectProduct/${id}`,
    matchGlb: "matchGlb/:id",
    matchGlbFunc: (id) => `matchGlb/${id}`,
  },
  uploadGlBFunc: (id) => `/upload-glb/${id}`,
  editGlb: "/edit-glb/:id",
  manageGlb: "/manage-glb",
  uplaodTextures: "/upload-texture",
  manageTextures: "/manage-textures",
  editTextures: "/edit-textures/:id",
  uploadGlbHdrs: "/upload-hrds",
  manageGlbHdrs: "/manage-hrds",
  editGlbHdrs: "/edit-hdri/:id",
  userProfile: "/userProfile",
  publicProfile: "/publicProfile/:id",
  publicProfileFunc: (id) => `/publicProfile/${id}`,
  productDetails: "/productDetails/:id",
  productDetailsFunc: (id) => `/productDetails/${id}`,
  // end user routes
  endUserGlbs: "/endUserGlbs",
  transferToProduct: "/transferToProduct/:id",
  transferToProductFunc: (id) => `/transferToProduct/${id}`,
  likedGlbs: "/likedGlbs",
  orders: "/orders",
  orderDetails: "/orderDetails/:id",
  orderDetailsFunc: (id) => `/orderDetails/${id}`,
  checkout: "/checkout/:id",
  checkoutFunc: (id) => `/checkout/${id}`,
  transferToGame: "/transferToGame/:id",
  transferToGameFunc: (id, mod_id) => `/transferToGame/${id}?mod_id=${mod_id}`,
  landing: "/",
  // developer routes
  addGame: "/addGame",
  listOfGames: "/list_of_games",
  addGameGlbs: "/add_game_glb/:id",
  addGameGlbsFunc: (id) => `/add_game_glb/${id}`,
  allGameGlb: "/game_glbs/:id",
  allGameGlbFunc: (id) => `/game_glbs/${id}`,
  chooseGameToTransferTo: `/transfer_sales/:id`,
  rootChooseGameToTransferTo: `transfer_sales`,
  chooseGameToTransferToFunc: (id) => `/transfer_sales/${id}`,
  // internal routes
  internalTextureTransfer: "/internal/texture_transfer",
};
