const imageSlides = [
  {
    title: `
            <span>Be You:</span> Create styles that express you and your lifestyle.
      `,
    sub_title: "Unleash your creativity and design a style that’s unmistakably you.",
    url: "/images/image-1.png",
    },
    {
      title: `
            <span>Live You:</span> Wear your favorite garments in your favorite games
      `,
      sub_title: "Step into the virtual world dressed in your favorite outfits.",
      url: "/images/image-4.png",
    },
    {
      title: `
            <span>Scale You:</span> Order as many product as fits your brands across the world.
      `,
      sub_title: "Spread your unique style—order as many pieces as you need, worldwide.",
      url: "/images/image-3.png",
    },
];
export default imageSlides;  