import axios from "axios";
import { apiRoutes, authetictedAxios } from "../../../../../utils/api";
import { v4 } from "uuid";
import { client } from "../../../../../index";
import { ADD_USER_GLB_MOD } from "../../../../../graphql/adduserGlbMod.mutation";
import { appRoutes } from "../../../../../utils/appRoutes";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { handleLookAtMesh } from "../customization/handleLookAtMesh";
import { GLB_NAME } from "../loaders/handleGlb";

async function uploadCanvas(pattern) {
  const canvas = document.getElementById(pattern.id);
  if (canvas) {
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );
    const fileUploadUrlResponse = await authetictedAxios().post(
      apiRoutes.getSignedUrl,
      {
        filename: `patternExports/${v4()}.png`,
        filetype: "image/png",
      }
    );

    if (fileUploadUrlResponse?.data?.signedUrl) {
      const fileUploadUrl = fileUploadUrlResponse.data.signedUrl;

      await axios.put(fileUploadUrl, blob, {
        headers: {
          "Content-Type": "image/png",
        },
      });

      // Trigger a custom event with the canvasId and uploaded URL
      const uploadedUrl = fileUploadUrlResponse.data.url;
      pattern.url = uploadedUrl;
      const uploadEvent = new CustomEvent("canvasUploaded", {
        detail: { canvasId: pattern.id, pattern },
      });
      document.dispatchEvent(uploadEvent);

      return { canvasId: pattern.id, pattern };
    } else {
      console.error(`Failed to get signed URL for canvas ID: ${pattern.id}`);
      return null;
    }
  } else {
    console.error(`Canvas element with ID ${pattern.id} not found.`);
    return null;
  }
}

function uploadCanvasesInParallel(patterns) {
  return new Promise((resolve) => {
    const uploadedUrls = {};

    const onCanvasUploaded = (event) => {
      const { canvasId, pattern } = event.detail;
      uploadedUrls[canvasId] = pattern;

      // Check if all canvases are uploaded
      if (Object.keys(uploadedUrls).length === patterns.length) {
        document.removeEventListener("canvasUploaded", onCanvasUploaded);
        resolve(uploadedUrls);
      }
    };

    document.addEventListener("canvasUploaded", onCanvasUploaded);

    // Start uploading all canvases in parallel
    patterns.forEach((pattern) => {
      uploadCanvas(pattern).catch((error) =>
        console.error(`Error uploading canvas ${pattern}:`, error)
      );
    });
  });
}

async function exportSceneAndUpload(scene, key) {
  return new Promise((resolve, reject) => {
    const exporter = new GLTFExporter();

    exporter.parse(
      scene,
      async (gltf) => {
        try {
          // Convert the glTF object to a JSON string
          const gltfJson = JSON.stringify(gltf);

          // Convert the JSON string to a Blob
          const blob = new Blob([gltfJson], { type: "model/gltf+json" });

          // Get the signed upload URL
          const fileUploadUrlResponse = await authetictedAxios().post(
            apiRoutes.getSignedUrl,
            {
              filename: key,
              filetype: "model/gltf+json", // GLTF file type
            }
          );

          const {
            signedUrl,
            url: cloudFrontUrl,
            key: final_key,
          } = fileUploadUrlResponse.data;

          // Upload the GLTF file to S3
          await axios.put(signedUrl, blob, {
            headers: {
              "Content-Type": "model/gltf+json",
            },
          });

          // Construct the CloudFront URL
          resolve({ key: final_key, url: cloudFrontUrl });
        } catch (error) {
          reject(error);
        }
      },
      { binary: false } // Export as a JSON GLTF file
    );
  });
}

export async function exportSceneAsGLB(
  macth_info,
  name,
  setLoadingPopup,
  scene,
  id,
  controls,
  camera
) {
  setLoadingPopup(true);
  for (let i = 0; i < scene.children.length; i++) {
    const element = scene.children[i];
    if (element.name === GLB_NAME) {
      handleLookAtMesh(camera, element, controls);
      break;
    }
  }
  const { patternRes } = macth_info;
  const result = await uploadCanvasesInParallel(patternRes);
  const configuratorCanvas = await uploadCanvasesInParallel([
    {
      id: process.env.REACT_APP_CONFIGURATOR_ID,
    },
  ]);
  const glbInfo = await exportSceneAndUpload(scene, `glbExport/${v4()}.glb`);
  await client.mutate({
    mutation: ADD_USER_GLB_MOD,
    variables: {
      image_key: configuratorCanvas[process.env.REACT_APP_CONFIGURATOR_ID].url
        .split(process.env.REACT_APP_CLOUDFRONT_URL)
        .pop(),
      placeholder_image_url:
        configuratorCanvas[process.env.REACT_APP_CONFIGURATOR_ID].url,
      print_pattern_key: result,
      name: name.name,
      source_glb: id,
      glb_url: glbInfo.url,
      glb_key: glbInfo.key,
    },
  });
  setLoadingPopup(false);
  window.location.href = appRoutes.endUserGlbs;
}
