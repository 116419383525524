import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Upload Glb', 'Choose Merchize Product', 'Match Patterns'];

export default function Steps({ activeStep, handleBack, handleNext }) {

    return (
        <Box sx={{ width: '100%', marginBottom: "30px", display: "flex", gap: "60px" }}>
            {
                activeStep !== 0 && <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={handleBack}>
                    <path d="M57 54.8043L0.977783 54.8043L0.977783 0L57 0L57 54.8043Z" fill="#16191C" />
                    <path d="M36.2962 28.0111H21.2554M21.2554 28.0111L28.5627 35.3183M21.2554 28.0111L28.5627 20.7038" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            }

            <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
                {steps.map((label) => {
                    return (
                        <Step key={label}  >
                            <StepLabel sx={{
                                "& .Mui-active": {
                                    "& circle": {
                                        color: "#C4FF03"
                                    },
                                    "& text": {
                                        opacity: 1,
                                    }
                                },
                                "& circle": {
                                    color: "#8EA397"
                                },
                                "& .Mui-disabled": {
                                    opacity: 0.5,
                                },
                                "& .Mui-completed": {
                                    "& path": {
                                        color: "#C4FF03"
                                    },
                                }
                            }}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep !== 2 && <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={handleNext}>
                <path d="M57 54.8043L0.977783 54.8043L0.977783 0L57 0L57 54.8043Z" fill="#16191C" />
                <path d="M21.2555 28.0111H36.2962M36.2962 28.0111L28.989 20.7039M36.2962 28.0111L28.989 35.3183" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>}

        </Box>
    );
}

