import axios from "axios";

export const unAuthetticatedAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

export const authetictedAxios = () => {
  const token = localStorage.getItem("token");
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadFile = async (file, fileType) => {
  // Get the signed upload URL
  const fileUploadUrlResponse = await authetictedAxios().post(
    apiRoutes.getSignedUrl,
    {
      filename: file.name,
      filetype: fileType,
    }
  );

  const {
    signedUrl,
    url: cloudFrontUrl,
    key: final_key,
  } = fileUploadUrlResponse.data;

  // Upload the file to S3
  await axios.put(signedUrl, file, {
    headers: {
      "Content-Type": fileType,
    },
  });

  // Return the CloudFront URL
  return { key: final_key, url: cloudFrontUrl };
};

export const apiRoutes = {
  signup: "/auth/signup",
  comfirmEmail: (token) => `/auth/comfirm/${token}`,
  login: "/auth/login",
  fileUpload: "/file-upload",
  getSignedUrl: "/file-upload/get_signed_url",
  deleteFile: "/file-upload/delete",
  stableDiffusion: "/stable-diffusion",
  stableDiffusionAdmin: "/stable-diffusion/admin",
  likes: "likes",
  doesUsernameExist: "/manage-user-profile/user-exist",
  userPublicProfile: "/user-public-profile",
  bakeTexture: "/bake-texture",
  merchizeProducts: "/merchize/products",
  singleMerchizeProduct: `/merchize/singleProductInfo/:id`,
  singleMerchizeProductFunc: (id) => `/merchize/singleProductInfo/${id}`,
  textureTransfer: "/texture-transfer",
  payForCartItems: "/payment/pay",
  verifyPaymentFunc: (id) => `/payment/verify_payment/${id}`,
  matchGlbInfo: `match-glb`,
  matchGlbAndPatternInfo: `match-glb/info`,
  createMerchizePattern: "/pattern-generation/merchize_pattern",
  addCartItems: "/cart",
  getCartInfoFunc: (id) => `/cart/info/${id}`,
  listOfOrder: "/orders",
  singleOrderDetailsFunc: (id) => `/orders/${id}`,
};

export const users = {
  client: "client",
  enduser: "enduser",
  sdk_developer: "sdk_developer",
};

export const defaultQueryOption = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  cacheTime: 0,
};
