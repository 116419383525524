import React from "react";
import RightPageDetails from "../../layout/RightPageDetails.layout";
import styles from "./OrdersDetails.module.scss"
import { useQuery } from "react-query";
import { apiRoutes, authetictedAxios } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Alert, LinearProgress } from "@mui/material";

export default function OrdersDetails() {
  const { id } = useParams()
  const { isLoading, data, isError } = useQuery(apiRoutes.singleOrderDetailsFunc(id), async () => {
    const res = await authetictedAxios().get(apiRoutes.singleOrderDetailsFunc(id))
    if (res) {
      return res.data
    }
  })
  return (
    <RightPageDetails title="Order Details" subTitle="Details for a specific order">
      <div id={styles.OrdersDetails}>
        {
          isLoading && <LinearProgress color="info" />
        }
        {isError && <Alert severity="error">There was an error loading order details.</Alert>}
        {
          data && <><div className="current-status">
            Order <span>{id}</span>was placed on <span>{data.updated_at}</span>and is currently <span>{data.status}</span>.
          </div>
            <div className="od-title">The Details</div>
            <div className="od-grid">
              <div>
                <div className="row">
                  <div className="row-item">Product</div>
                  <div className="row-item">Size</div>
                  <div className="row-item">Items</div>
                  <div className="row-item">Cost</div>
                </div>
                {data.cartInfo.cartItems.map((item, idx) => {
                  return (<SingleOrderItem key={idx} item={item} />)
                })}
                <div className="od-footer">
                  <div className="og-f-row">
                    <div className="name">Subtotal:</div>
                    <div className="value">{process.env.REACT_APP_CURRENCY}{" "}{data.cartInfo.total
                    }</div>
                  </div>
                  <div className="og-f-row">
                    <div className="name">Shipping:</div>
                    <div className="value">$0</div>
                  </div>
                  <div className="og-f-row">
                    <div className="name">Total:</div>
                    <div className="value"><b>{process.env.REACT_APP_CURRENCY}{" "}{data.cartInfo.total
                    }</b></div>
                  </div>
                </div>
              </div>
              <div className="ship-det">
                <div className="s-det">
                  <section>
                    <div className="s-det-title">Billing Address</div>
                    <div className="sub-title">
                      <b>Name:</b>{" "}{data.personalDetails.first_name}{" "}{data.personalDetails.last_name}<br />
                      <b>Company Name:</b>{" "}{data.personalDetails.company_name}<br />
                      <b>Postcode:</b>{" "}{data.personalDetails.postcode}<br />
                      <b>Street Address:</b>{" "}{data.personalDetails.street_address}<br />
                      <b>Address 2:</b>{" "}{data.personalDetails.address_2}<br />
                      <b>city:</b>{" "}{data.personalDetails.city}<br />
                      <b>state:</b>{" "}{data.personalDetails.state}<br />
                      <b>country:</b>{" "}{data.personalDetails.country}<br />
                      <b>email:</b>{" "}{data.personalDetails.email_address}<br />
                      <b>phone:</b>{" "}{data.personalDetails.phone}<br />
                    </div>
                  </section>
                  <section>
                    <div className="s-det-title">Shipping Address</div>
                    <div className="sub-title">
                      <b>Name:</b>{" "}{data.personalDetails.ship_first_name}{" "}{data.personalDetails.ship_last_name}<br />
                      <b>Company Name:</b>{" "}{data.personalDetails.ship_company_name}<br />
                      <b>Postcode:</b>{" "}{data.personalDetails.ship_postcode}<br />
                      <b>Street Address:</b>{" "}{data.personalDetails.ship_street_address}<br />
                      <b>Address 2:</b>{" "}{data.personalDetails.ship_address_2}<br />
                      <b>City:</b>{" "}{data.personalDetails.ship_city}<br />
                      <b>state:</b>{" "}{data.personalDetails.ship_state}<br />
                      <b>country:</b>{" "}{data.personalDetails.ship_country}<br />
                      <b>country:</b>{" "}{data.personalDetails.ship_country}<br />
                      <b>email:</b>{" "}{data.personalDetails.ship_email_address}<br />
                      <b>phone:</b>{" "}{data.personalDetails.ship_phone}<br />
                    </div>
                  </section>
                  {data.personalDetails.order_notes && <section className="small-details">
                    <img src="/images/info.svg" alt="" />
                    <div className="info-fg">{data.personalDetails.order_notes}</div>
                  </section>}
                </div>
              </div>
            </div></>
        }
      </div>
    </RightPageDetails>
  );
}
function SingleOrderItem({ item }) {
  return (
    <div className="row">
      <div className="body-row-item">
        <div className="product-info">
          <div className="img-bg" style={{ backgroundImage: `url("${item.item.user_moded_glb.placeholder_image_url}")` }}></div>
          <div className="product-info-det">
            {item.item.user_moded_glb.name}
          </div>
        </div>
      </div>
      <div className="body-row-item">{item.item.variant}</div>
      <div className="body-row-item">{item.item.count} items</div>
      <div className="body-row-item">{process.env.REACT_APP_CURRENCY}{" "}{item.merchizeInfo.retail_price}</div>
    </div>
  );
}

