import React from 'react'
import RightPageDetails from '../../layout/RightPageDetails.layout'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useMutation as useTanMutation } from 'react-query'
import { LIST_OF_GAMES_GLB } from '../../graphql/listOfGamesGlb.query'
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'
import { apiRoutes, authetictedAxios } from '../../utils/api'
import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'

export default function TransferToGame() {
  const { search } = useLocation()
  const { id } = useParams()
  const { loading, data, error } = useQuery(LIST_OF_GAMES_GLB, { fetchPolicy: "no-cache", variables: { id } })

  const USER_MOD_GLB = gql`query USER_MOD_GLB($id:Int!) {
  user_moded_glb_by_pk(id:$id){
    glb_url
  }
}
`
  const [modInfo] = useLazyQuery(USER_MOD_GLB)
  const { isLoading, data: textureTransferData, isError, mutate } = useTanMutation(apiRoutes.textureTransfer, async (target_glb) => {
    const params = new URLSearchParams(search);
    const mod_id = params.get('mod_id');
    const userInfo = await modInfo({ variables: { id: mod_id } })
    const res = await authetictedAxios().post(apiRoutes.textureTransfer, {
      "source_glb": userInfo.data.user_moded_glb_by_pk.glb_url,
      "target_glb": target_glb
    })
    if (res) {
      return res.data
    }
  })
  return (
    <RightPageDetails title="Transfer Glb" subTitle="Transfer the mod to glb">
      <div style={{
        marginTop: "20px"
      }}>
        {isLoading && <Backdrop open={true} sx={{ zIndex: "100" }}>
          <CircularProgress color='info' />
        </Backdrop>}
        {isError && <Alert severity='error'> There was an error texture transferring item</Alert>}
        {textureTransferData && <Backdrop open={true} sx={{ zIndex: "100" }}>
          <div>
            <Typography variant='h3'>The transferred Glb</Typography>
            <model-viewer
              style={{ width: "300%", height: "300px", background: "white", marginBottom: "20px" }}
              alt="glb preview"
              src={textureTransferData.final_glb}
              ar
              environment-image="/hdri/blue_photo_studio_1k.hdr"
              shadow-intensity="1"
              camera-controls
              touch-action="pan-y"
            ></model-viewer>
          </div>
        </Backdrop>}
        {loading && <LinearProgress color="info" />}
        {error && <Alert severity='error'>There was an error retrieving list of game glbs</Alert>}
      </div>
      <Grid container spacing={2}>
        {data && data.sdk_game_glbs.map((item) => {
          return <Grid item xs={3}>
            <Box sx={{
              padding: "12px",
              background: "#000000"
            }}>
              <model-viewer
                style={{ width: "100%", height: "300px", background: "white", marginBottom: "20px" }}
                alt="glb preview"
                src={item.glb_url}
                ar
                environment-image="/hdri/blue_photo_studio_1k.hdr"
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              ></model-viewer>
              <Button variant="contained" color="info" width="100%" sx={{ width: "100%" }} onClick={() => mutate(item.glb_url)}>Transfer to asset</Button>
            </Box>
          </Grid>
        })}
      </Grid>
    </RightPageDetails >
  )
}
