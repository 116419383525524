import { gql } from "@apollo/client";

export const ADD_ORDER_DETAILS = gql`
  mutation MyMutation(
    $cart_id: uuid!
    $city: String!
    $company_name: String
    $email_address: String!
    $first_name: String!
    $last_name: String!
    $order_notes: String
    $phone: String!
    $ship_city: String!
    $ship_company_name: String
    $ship_email_address: String!
    $ship_first_name: String!
    $ship_last_name: String!
    $ship_phone: String!
    $ship_street_address: String!
    $street_address: String!
    $country: String!
    $ship_country: String!
    $ship_postcode: String!
    $postcode: String!
    $state: String!
    $ship_state: String!
    $address_2: String!
    $ship_address_2: String!
  ) {
    insert_order_personal_details_one(
      object: {
        cart_id: $cart_id
        city: $city
        company_name: $company_name
        email_address: $email_address
        first_name: $first_name
        last_name: $last_name
        order_notes: $order_notes
        phone: $phone
        ship_city: $ship_city
        ship_company_name: $ship_company_name
        ship_email_address: $ship_email_address
        ship_first_name: $ship_first_name
        ship_last_name: $ship_last_name
        ship_phone: $ship_phone
        ship_street_address: $ship_street_address
        street_address: $street_address
        country: $country
        ship_country: $ship_country
        postcode: $postcode
        ship_postcode: $ship_postcode
        state: $state
        ship_state: $ship_state
        address_2: $address_2
        ship_address_2: $ship_address_2
      }
    ) {
      id
    }
  }
`;
