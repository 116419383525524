import { Button, FormLabel } from "@mui/material";
import React, { useContext } from "react";
import styles from "./updateTexturePopUp.module.scss";
import { useState } from "react";
import { TextareaAutosize } from "@mui/base";
import { MenuItem, Select } from "@mui/material"
import { diffusionStyles } from "../../constants";
import { STABLE_DIFFUSION_CONTEXT } from "../../StableDiffusion";

function UpdateTexturePopUp(props) {
  const { content, style: dbStyle } = props;
  const { setAnchorEl, handlePrompt, refetch } = useContext(STABLE_DIFFUSION_CONTEXT)
  const [text, setText] = useState(content);

  const handleChangeDetails = (e) => {
    setText(e.target.value);
  };

  const [style, setStyle] = useState(dbStyle)
  const handleStyleChange = (e) => {
    if (e?.target) {
      setStyle(e.target.value)
    }
  }

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    handlePrompt(text, style, refetch);
    setAnchorEl(null);
  };



  return (
    <form className={styles.updateTexturePopUp} onSubmit={handleSubmitUpdate}>
      <div className="header">
        <div className="box" onClick={() => setAnchorEl(false)}>
          <img src="/images/closeUpdate.svg" alt="Close" className="closeIcon" />
        </div>
      </div>
      <TextareaAutosize className="content" value={text} onChange={handleChangeDetails} />
      <div style={{
        display: "grid",
        gap: "8px"
      }}>
        <FormLabel about="white">Style</FormLabel>
        <Select
          value={style}
          label="Choose Styles"
          onChange={handleStyleChange}
          about="blurred"
        >
          {diffusionStyles.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>
          })}
        </Select>
      </div>
      <div className="actions">
        <Button variant="contained" size="small" color="primary" type="submit">
          Update Generation
        </Button>
      </div>
    </form>
  );
}

export default UpdateTexturePopUp;
