import React from "react";
import Button from "@mui/material/Button";

import styles from "./emptyFilter.module.scss";
import { Typography } from "@mui/material";

export default function EmptyFilter(props) {
  const { setSearchValue, title, desc } = props;

  const handleClick = () => {
    setSearchValue("");
  };

  return (
    <div className={styles.emptyFilter}>
      <img src="/images/no_match.svg" alt="no match" className="bg_empty" />
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2" color="#ffffff50 !important" className="details">
        {desc}
      </Typography>
      {!props?.hideReset && (
        <Button variant="contained" color="primary" onClick={handleClick} className="btn">
          Reset Filter
        </Button>
      )}
    </div>
  );
}
