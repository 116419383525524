import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Box, Grid, Typography, CircularProgress } from "@mui/material";
import RightPageDetails from "../../layout/RightPageDetails.layout";
import { useMutation } from "react-query";
import { apiRoutes, authetictedAxios, uploadFile } from "../../utils/api";

const TextureTransferInternal = () => {
  const [sourcePreviewUrl, setSourcePreviewUrl] = useState(null);
  const [targetPreviewUrl, setTargetPreviewUrl] = useState(null);

  const { isLoading, isError, data, mutate } = useMutation(
    "internal_texture_transfer",
    async (values) => {
      try {
        // Upload Source File
        let source_glb, target_glb;
        if (values.source) {
          const file1Type = values.source.name.split(".").pop();
          const file1UploadResult = await uploadFile(
            values.source,
            `model/${file1Type}`
          );
          source_glb = file1UploadResult.url;
        }

        // Upload Target File
        if (values.target) {
          const file2Type = values.target.name.split(".").pop();
          const file2UploadResult = await uploadFile(
            values.target,
            `model/${file2Type}`
          );
          target_glb = file2UploadResult.url;
        }

        const res = await authetictedAxios().post(apiRoutes.textureTransfer, {
          source_glb,
          target_glb,
        });
        if (res) {
          return res.data;
        }
      } catch (error) {
        console.error("File upload failed: ", error);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      source: null,
      target: null,
    },
    validationSchema: Yup.object({
      source: Yup.mixed()
        .required("Source file is required")
        .test(
          "fileSize",
          "File too large, max size is 50MB",
          (value) => !value || (value && value.size <= 50 * 1024 * 1024)
        )
        .test(
          "fileFormat",
          "Unsupported Format. Only .glb files are allowed",
          (value) =>
            !value ||
            (value &&
              (value.type === "model/gltf-binary" ||
                value.name.includes("glb")))
        ),
      target: Yup.mixed()
        .required("Target file is required")
        .test(
          "fileSize",
          "File too large, max size is 50MB",
          (value) => !value || (value && value.size <= 50 * 1024 * 1024)
        )
        .test(
          "fileFormat",
          "Unsupported Format. Only .glb files are allowed",
          (value) =>
            !value ||
            (value &&
              (value.type === "model/gltf-binary" ||
                value.name.includes("glb")))
        ),
    }),
    onSubmit: mutate,
  });

  const handleFileChange = (event, fileName) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue(fileName, file);

    if (fileName === "source" && file) {
      setSourcePreviewUrl(URL.createObjectURL(file));
    } else if (fileName === "target" && file) {
      setTargetPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <RightPageDetails
      title="Texture transfer"
      subTitle="Internal texture transfer"
    >
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
        <Typography variant="h5" mb={2}>
          File Upload Form
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              startIcon={<FileUpload />}
              color={
                formik.touched.source && formik.errors.source
                  ? "error"
                  : "primary"
              }
            >
              Upload Source
              <input
                type="file"
                hidden
                onChange={(event) => handleFileChange(event, "source")}
              />
            </Button>
            {formik.touched.source && formik.errors.source ? (
              <Typography color="error" variant="body2">
                {formik.errors.source}
              </Typography>
            ) : null}
            {sourcePreviewUrl && (
              <Box mt={2}>
                <model-viewer
                  style={{ width: "100%", height: "300px" }}
                  alt="Source GLB Preview"
                  src={sourcePreviewUrl}
                  ar
                  camera-controls
                  touch-action="pan-y"
                ></model-viewer>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              startIcon={<FileUpload />}
              color={
                formik.touched.target && formik.errors.target
                  ? "error"
                  : "primary"
              }
            >
              Upload Target
              <input
                type="file"
                hidden
                onChange={(event) => handleFileChange(event, "target")}
              />
            </Button>
            {formik.touched.target && formik.errors.target ? (
              <Typography color="error" variant="body2">
                {formik.errors.target}
              </Typography>
            ) : null}
            {targetPreviewUrl && (
              <Box mt={2}>
                <model-viewer
                  style={{ width: "100%", height: "300px" }}
                  alt="Target GLB Preview"
                  src={targetPreviewUrl}
                  ar
                  camera-controls
                  touch-action="pan-y"
                ></model-viewer>
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Transfer"
              )}
            </Button>
            {isError && (
              <Typography color="error" variant="body2" mt={2}>
                File upload failed. Please try again.
              </Typography>
            )}
          </Grid>

          {data && (
            <Box
              sx={{
                padding: "12px",
                display: "grid",
                placeItems: "center",
                width: "100%",
                paddingTop: "20px"
              }}
            >
              <Typography variant="h5" mb={2}>
                Final Glb
              </Typography>
              <model-viewer
                style={{
                  width: "500px",
                  height: "500px",
                  background: "white",
                  marginTop: "8px",
                }}
                alt="Final GLB Preview"
                src={data.final_glb}
                ar
                environment-image="/hdri/blue_photo_studio_1k.hdr"
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              ></model-viewer>
            </Box>
          )}
        </Grid>
      </Box>
    </RightPageDetails>
  );
};

const FileUpload = () => {
  return (
    <img
      src="/images/cloud-upload-alt.svg"
      width="20px"
      height="20px"
      alt="file upload"
    />
  );
};

export default TextureTransferInternal;
