import React, { createContext, useRef, useState } from 'react'
import styles from "./UploadGlb.module.scss";
import HDRIViewer from '../HdriViewer/HDRIViewer';
import { Button } from '@mui/material';

export const Context = createContext()
export default function FormLayout(props) {
  const { children, title, subTitle } = props;
  const glbPlaceholder = "/images/upload_glb_image.svg";
  const glbPreviewImg = "/images/upload_placeholder_image.svg"
  const hdriLayout = "/images/upload_hdri_image.svg"
  const handleConstistency = () => {
    if (props.imagePreview && !props.imagePreviewTitle) {
      throw new Error("imagePreviewTitle is required")
    }
  }
  const modelViewerRef = useRef(null)

  const [glbImage, setGlbImage] = useState(null)
  const [uploadablePreview, setUploadablePreview] = useState(null)
  const viewGlbImage = () => {
    const canvas = modelViewerRef.current.shadowRoot.querySelector("canvas")
    setGlbImage(canvas.toDataURL('image/png'))
    canvas.toBlob((blob) => {
      const file = new File([blob], 'image.png', { type: 'image/png' });
      setUploadablePreview(file)
    }, 'image/png');
  }
  return (
    <Context.Provider value={{ edit: props.edit, uploadablePreview, setUploadablePreview, setGlbImage }}>
      <div className={styles.uploadGlb}>
        {handleConstistency()}
        <div className="upload">
          <div className="top_content">
            <div className="title">{title}</div>
            <div className="sub_title">{subTitle}</div>
          </div>
          {children}
        </div>
        <div className={props.userProfile ? "preview fitContent" : "preview"}>
          {props.glbPreview !== undefined && (
            <div className="preview_img glb">
              <div className="title">Glb Preview</div>
              <div className="img glb_img" style={{ backgroundImage: `url('${props.glbPreview || glbPlaceholder}')` }}>
                {props.glbPreview && (
                  <>
                    <model-viewer
                      style={{ width: "100%", height: "100%" }}
                      alt="glb preview"
                      src={props.glbPreview}
                      ar
                      environment-image="/hdri/blue_photo_studio_1k.hdr"
                      shadow-intensity="1"
                      camera-controls
                      touch-action="pan-y"
                      ref={modelViewerRef}
                    ></model-viewer>
                    <div className="center">
                      <Button variant="contained" onClick={viewGlbImage}>
                        Use as preview image
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {props.previewHdri !== undefined && (
            <div className="preview_img glb">
              <div className="title">Hdri Preview</div>
              <div className="img glb_img" style={{ backgroundImage: `url('${props.previewHdri || hdriLayout}')` }}>
                {props.previewHdri && <HDRIViewer hdr={props.previewHdri} />}
              </div>
            </div>
          )}
          {(props.imagePreview !== undefined || glbImage) && (
            <div className="preview_img">
              <div className="title">{props.imagePreviewTitle}</div>
              {console.log("the file url", props.imagePreview)}
              <div className={props.userProfile ? "img placeholder userImage" : `img placeholder ${props.imagePreview && "contain-image"}` } style={{ backgroundImage: `url('${glbImage || props.imagePreview || glbPreviewImg}')` }}></div>
            </div>
          )}
        </div>
      </div>
    </Context.Provider>
  );
}
