import Portal from "./Portal";
import React, { useContext, useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { homeContext } from "../../Home";
import { shirt } from "../PageManIconPart/PageManIconPart";
import { CHANGE_TEXTURE } from "./events/fireNewTexture";
import { EXPORT_GLB } from "../configurator/events/exportGlb";
import { CHANGE_COLOR_FABRIC } from "./events/changeColor";

export function PatternEditor({ canvasId, width, height, pattern_url }) {
    const canvasRef = useRef(null);
    const { activeIcon, selectedPattern, editPosition, setEditPosition } = useContext(homeContext)

    useEffect(() => {
        // Create a new fabric canvas
        const proceses = {
            rect: null
        }
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: width,
            height: height,
        });

        proceses.rect = new fabric.Rect({
            left: 0,           // X position
            top: 0,            // Y position
            fill: "white",        // Fill color
            width: 2000,        // Width of the rectangle
            height: 2000        // Height of the rectangle
        });
        canvas.add(proceses.rect)
        // Load the image from the pattern URL and add it to the canvas at position (0, 0)
        fabric.Image.fromURL(pattern_url, (img) => {
            img.scale(1).set('flipX', true);
            img.set({
                left: 0,
                top: 0

            });
            img.lockMovementX = true;
            img.lockMovementY = true;
            img.lockScalingX = true;
            img.lockScalingY = true;
            img.lockRotation = true;
            img.selectable = false;
            canvas.add(img)
        }, { crossOrigin: 'anonymous' })


        const handleChange = () => {
            if (proceses.selectedObject) {
                proceses.selectedObject.current.material.map.needsUpdate = true;
            }
        }

        const handleTextureChange = (e) => {
            const { texture, selectedObject } = e.detail.texture
            fabric.Image.fromURL(texture, (img) => {
                // Get the canvas dimensions
                const canvasWidth = canvas.getWidth();
                const canvasHeight = canvas.getHeight();

                // Scale the image to fit the canvas width
                const scaleFactor = canvasWidth / img.width;
                img.scale(scaleFactor);

                // Center the image on the canvas
                img.set({
                    left: (canvasWidth - img.getScaledWidth()) / 2,
                    top: (canvasHeight - img.getScaledHeight()) / 2,
                    flipX: true,
                    selectable: true,  // Make the image selectable
                    evented: true
                });

                // Add the image to the canvas
                canvas.add(img);
                proceses.selectedObject = selectedObject
                selectedObject.current.material.map.needsUpdate = true;
            }, { crossOrigin: 'anonymous' });
        }

        const handleColorChange = (e) => {
            const { color, selectedObject, } = e.detail
            // Add a rectangle to the canvas
            // Add the rectangle to the canvas
            proceses.rect.set({
                fill: color       // Set the fill color to red
            });
            canvas.bringToFront(proceses.rect);
            proceses.selectedObject = selectedObject
            selectedObject.current.material.map.needsUpdate = true;
        }

        canvas.on("after:render", handleChange)
        var container = canvasRef.current
        container.addEventListener(CHANGE_COLOR_FABRIC, handleColorChange)
        container.addEventListener(CHANGE_TEXTURE, handleTextureChange)

        const handleGlbExport = () => {
            canvas.discardActiveObject()
            canvas.requestRenderAll()
        }
        document.addEventListener(EXPORT_GLB, handleGlbExport);
        return () => {
            canvas.dispose();
            document.removeEventListener(EXPORT_GLB, handleGlbExport);
            container.removeEventListener(CHANGE_COLOR_FABRIC, handleColorChange)
            container.removeEventListener(CHANGE_TEXTURE, handleTextureChange)
        };
    }, [canvasId, width, height, pattern_url]);

    const handleOpacity = () => {
        if (activeIcon === shirt) {
            if (selectedPattern === canvasId) {
                return "block"
            }
            return "none"
        }
        return "none"
    }

    return (
        <Portal>
            <div style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: "100",
                pointerEvents: editPosition ? "all" : "none",
                opacity: editPosition ? 1 : 0,
                display: handleOpacity(),
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <svg width="143" height="143" viewBox="0 0 143 143" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => setEditPosition(false)}>
                        <rect width="143" height="143" rx="71.5" fill="#262626" />
                        <path d="M115 35.063L92.253 57.81L106.4 72H72V37.6L86.147 51.747L108.937 29L115 35.063ZM35.063 115L57.81 92.253L72 106.4V72H37.6L51.747 86.147L29 108.937L35.063 115Z" fill="white" />
                    </svg>
                </div>
                <canvas id={canvasId} ref={canvasRef} />
            </div>
        </Portal>
    )
}