import React from "react";
import styles from "./Orders.module.scss";
import RightPageDetails from "../../layout/RightPageDetails.layout";
import { useQuery } from "react-query";
import { apiRoutes, authetictedAxios, defaultQueryOption } from "../../utils/api";
import { Alert, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../utils/appRoutes";

const SingleOrder = ({orderId,orderDate,orderStatus,price,count}) => {
  const navigate=useNavigate()
  const handleNav=()=>{
    navigate(appRoutes.orderDetailsFunc(orderId))
  }
  return (<div className="row">
    <div className="row-item">{orderId}</div>
    <div className="row-item">{orderDate}</div>
    <div className="row-item">
      <div className="status-chip">
        <img src="/images/info.svg" alt="" />
        <div className="txt">{orderStatus}</div>
      </div>
    </div>
    <div className="row-item"><span>{process.env.REACT_APP_CURRENCY}{" "}{price}</span>for {count} items</div>
    <div className="row-item">
      <button className="view-order" onClick={handleNav}>View</button>
    </div>
  </div>)
}
export default function Orders() {
  const { isLoading,isError,data } = useQuery(apiRoutes.listOfOrder,async ()=>{
    const res=await authetictedAxios().get(apiRoutes.listOfOrder)
    if(res){
      return res.data
    }
  }, defaultQueryOption)
  return (
    <RightPageDetails title="Orders" subTitle="All order you have ordered">
      {isLoading && <LinearProgress color="info" />}
      {data && <div div id={styles.orders}>
      <div className="row">
        <div className="header-item">Order</div>
        <div className="header-item">Date</div>
        <div className="header-item">Status</div>
        <div className="header-item">Total</div>
        <div className="header-item">View</div>
      </div>
        {data.payments.map((singleOrder)=>{
          return <SingleOrder key={singleOrder.cart_id} count={singleOrder.cartInfo.count} orderDate={singleOrder.updated_at} orderId={singleOrder.cart_id} orderStatus={singleOrder.status  || "The order is being processed"} price={singleOrder.cartInfo.total} />
      })}
    </div>}
    {isError && <Alert  severity="error">There was an error retrieving the list of orders</Alert>}
    </RightPageDetails>
  );
}
