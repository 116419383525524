import { createSlice, current } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cartOpen: false,
    cartItems: [],
    anchorEl: null,
    cartItemsCount: 0,
  },
  reducers: {
    setCartOpen: (state, action) => {
      state.cartOpen = action.payload;
    },
    handleClick: (state, action) => {
      state.anchorEl = action.payload.anchorEl;
      state.cartOpen = !state.cartOpen;
    },
    handleClose: (state) => {
      state.anchorEl = null;
      state.cartOpen = !state.cartOpen;
    },
    addCartItem: (state, action) => {
      const old = JSON.parse(JSON.stringify(current(state).cartItems));
      const newdata = action.payload;
      let count = 0;
      if (old.length === 0) {
        state.cartItems = [...state.cartItems, action.payload];
        state.cartItemsCount = action.payload.count;
      } else {
        let modIncrease = false;
        for (let i = 0; i < old.length; i++) {
          const item = old[i];
          if (
            item.modId === newdata.modId &&
            item.selectedSize === newdata.selectedSize
          ) {
            item.count = item.count + newdata.count;
            modIncrease = true;
          }
          count += item.count;
        }
        if (!modIncrease) {
          old.push(newdata);
          count += action.payload.count;
        }
        state.cartItems = [...old];
        state.cartItemsCount = count;
      }
    },
    deleteCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    increaseCartItemCount: (state, action) => {
      const itemCopy = [...state.cartItems];
      for (let i = 0; i < itemCopy.length; i++) {
        const item = itemCopy[i];
        if (item.id === action.payload.id) {
          item.count = item.count + 1;
        }
      }
      state.cartItems = itemCopy;
    },
    decreaseCartItemCount: (state, action) => {
      const itemCopy = [...state.cartItems];
      for (let i = 0; i < itemCopy.length; i++) {
        const item = itemCopy[i];
        if (item.id === action.payload.id) {
          item.count = item.count - 1;
        }
      }
      state.cartItems = itemCopy;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCartOpen,
  handleClick,
  handleClose,
  addCartItem,
  deleteCartItem,
  increaseCartItemCount,
  decreaseCartItemCount,
} = cartSlice.actions;

export default cartSlice.reducer;
