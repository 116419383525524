import { gql } from "@apollo/client";

export const UPDATE_GLB_MATCHING_FOR_GLB = gql`
  mutation UPDATE_GLB_MATCHING_FOR_GLB($id: uuid!, $match_obj: json!) {
    update_users_glbs_by_pk(
      pk_columns: { id: $id }
      _set: { pattern_match: $match_obj }
    ) {
      pattern_match
    }
  }
`;
