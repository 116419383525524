import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./pages/Home/Home";
import "./index.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { defaultTheme } from "./mui/theme";
import LoginSignupPages from "./pages/LoginSignupPages/LoginSignupPages";
import ComfirmEmail from "./pages/comfirmEmail/ComfirmEmail";
import UploadGlb from "./pages/UploadGlbPage/UploadGlb";
import ManageGlb from "./pages/manageGlbPage/ManageGlb";
import { appRoutes } from "./utils/appRoutes";
import UploadTexturesPage from "./pages/UploadTexturesPage/UploadTexturesPage";
import ManageTexture from "./pages/manageTexturesPage/ManageTexture";
import UploadHDRIPage from "./pages/UploadHdriPage/UploadHdriPage";
import ManageHdri from "./pages/manageHdriPage/ManageHdri";
import ListOfProducts from "./pages/listOfProducts/ListOfProducts";
import UnAutheticatedGraphql from "./providers/UnAutheticatedGraphql";
import GaurdClientRoutes from "./gaurds/GaurdClientRoutes";
import GaurdEndUserRoutes from "./gaurds/GaurdEndUserRoutes";
import ManageMods from "./pages/manageModsPage/ManageMods";
import UserProfile from "./pages/manageUserProfile/userProfile";
import PublicProfile from "./pages/publicProfile/publicProfile";
import PublicLayout from "./layout/publicPagesLayout.layout";
import GaurdClientAndEndUser from "./gaurds/GaurdClientAndEndUser";
import ManageLikes from "./pages/manageEndUserLikesPage/ManageLikes";
import LandingPage from "./pages/landing/LandingPage";
import ProductDetails from "./pages/productDetails/productDetails";
import Orders from "./pages/orders/Orders";
import OrdersDetails from "./pages/ordersDetails/OrdersDetails";
import MerchizeProductsPage from "./pages/MerchizeProductsPage/MerchizeProductsPage";
import Checkout from "./pages/checkout/Checkout";
import UploadStep from "./pages/UploadGlbPage/subPages/uploadGlb/UploadStep";
import SelectProduct from "./pages/UploadGlbPage/subPages/selectMerchizeProduct/SelectProduct";
import MatchGlb from "./pages/UploadGlbPage/subPages/matchGlb/MatchGlb";
import PaymentValidation from "./pages/paymentValidation/PaymentValidation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GaurdUserTypeRoutes from "./gaurds/GaurdUserTypeRoutes";
import { DEVELOPER, ENDUSER } from "./utils/constants";
import AddGame from "./pages/addGame/AddGame";
import ListOfGames from "./pages/listOfGames/ListOfGames";
import AddGameGlbs from "./pages/addGameGlbs/AddGameGlbs";
import AllGameGlb from "./pages/allGameGlb/AllGameGlb";
import TransferToGame from "./pages/transferToGame/TransferToGame";
import TextureTransferInternal from "./pages/textureTransferInternal/TextureTransferInternal";

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="app">
        <Router>
          <Routes>
            <Route
              path={appRoutes.home}
              exact
              element={
                <UnAutheticatedGraphql>
                  <PublicLayout>
                    <Home />
                  </PublicLayout>
                </UnAutheticatedGraphql>
              }
            />
            <Route
              path={appRoutes.editProduct}
              exact
              element={
                <PublicLayout>
                  <Home />
                </PublicLayout>
              }
            />
            <Route
              path={appRoutes.listOfProducts}
              element={
                <PublicLayout>
                  <ListOfProducts />
                </PublicLayout>
              }
            />
            <Route path={appRoutes.login} element={<LoginSignupPages />} />
            <Route path={appRoutes.signup} element={<LoginSignupPages />} />
            <Route
              path={appRoutes.signupWithUserType}
              element={<LoginSignupPages />}
            />
            <Route path={appRoutes.confirmEmail} element={<ComfirmEmail />} />
            <Route path={appRoutes.uploadGlb} element={<UploadGlb />}>
              <Route
                path={appRoutes.uploadGlbSubRoutes.uploadGlb}
                element={<UploadStep />}
              />
              <Route
                path={appRoutes.uploadGlbSubRoutes.edit}
                element={<UploadStep edit />}
              />
              <Route
                path={appRoutes.uploadGlbSubRoutes.selectProduct}
                element={<SelectProduct />}
              />
              <Route
                path={appRoutes.uploadGlbSubRoutes.matchGlb}
                element={<MatchGlb />}
              />
            </Route>
            <Route
              path={appRoutes.manageGlb}
              element={
                <GaurdClientRoutes>
                  <ManageGlb />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.uplaodTextures}
              element={
                <GaurdClientRoutes>
                  <UploadTexturesPage />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.manageTextures}
              element={
                <GaurdClientRoutes>
                  <ManageTexture />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.editTextures}
              element={
                <GaurdClientRoutes>
                  <UploadTexturesPage edit />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.uploadGlbHdrs}
              element={
                <GaurdClientRoutes>
                  <UploadHDRIPage />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.editGlbHdrs}
              element={
                <GaurdClientRoutes>
                  <UploadHDRIPage edit />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.manageGlbHdrs}
              element={
                <GaurdClientRoutes>
                  <ManageHdri />
                </GaurdClientRoutes>
              }
            />
            <Route
              path={appRoutes.userProfile}
              element={
                <GaurdClientAndEndUser>
                  <UserProfile />
                </GaurdClientAndEndUser>
              }
            />
            <Route path={appRoutes.publicProfile} element={<PublicProfile />} />

            {/* end user pages */}
            <Route
              path={appRoutes.endUserGlbs}
              element={
                <GaurdEndUserRoutes>
                  <ManageMods />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.transferToProduct}
              element={
                <GaurdEndUserRoutes>
                  <MerchizeProductsPage />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.productDetails}
              element={
                <GaurdEndUserRoutes>
                  <ProductDetails />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.likedGlbs}
              element={
                <GaurdEndUserRoutes>
                  <ManageLikes />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.orders}
              element={
                <GaurdEndUserRoutes>
                  <Orders />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.orderDetails}
              element={
                <GaurdEndUserRoutes>
                  <OrdersDetails />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.checkout}
              element={
                <GaurdEndUserRoutes>
                  <Checkout />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.payment}
              element={
                <GaurdEndUserRoutes>
                  <PaymentValidation />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.chooseGameToTransferTo}
              element={
                <GaurdEndUserRoutes>
                  <ListOfGames />
                </GaurdEndUserRoutes>
              }
            />
            <Route
              path={appRoutes.transferToGame}
              element={
                <GaurdEndUserRoutes>
                  <TransferToGame />
                </GaurdEndUserRoutes>
              }
            />
            {/* developer routes */}
            <Route
              path={appRoutes.addGame}
              element={
                <GaurdUserTypeRoutes userType={DEVELOPER}>
                  <AddGame />
                </GaurdUserTypeRoutes>
              }
            />
            <Route
              path={appRoutes.listOfGames}
              element={
                <GaurdUserTypeRoutes userType={DEVELOPER}>
                  <ListOfGames />
                </GaurdUserTypeRoutes>
              }
            />
            <Route
              path={appRoutes.addGameGlbs}
              element={
                <GaurdUserTypeRoutes userType={DEVELOPER}>
                  <AddGameGlbs />
                </GaurdUserTypeRoutes>
              }
            />
            <Route
              path={appRoutes.allGameGlb}
              element={
                <GaurdUserTypeRoutes userType={DEVELOPER}>
                  <AllGameGlb />
                </GaurdUserTypeRoutes>
              }
            />
            {/* internal routes */}
            <Route
              path={appRoutes.internalTextureTransfer}
              element={
                <GaurdUserTypeRoutes userType={ENDUSER} isInternal>
                  <TextureTransferInternal />
                </GaurdUserTypeRoutes>
              }
            />
            <Route path={appRoutes.landing} element={<LandingPage />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}
