import { gql } from "@apollo/client";

export const LIST_OF_GLBS = (search = "", category = "") => {
  return gql`
    query LIST_OF_GLBS {
      users_glbs(
        where: {
          pattern_match: { _is_null: false }
          ${search && `name: { _ilike: "%${search}%" }`}
          ${category && `category: { _eq: ${category} }`}
        }
      ) {
        SKU
        file_key
        glb_file_url
        name
        id
        placeholder_image_url
        placeholder_key
        private
      }
    }
  `;
};

export const NUMBER_OF_GLBS_LIKES=gql`
query NUMBER_OF_GLBS_LIKES($id:String!) {
  user_likes_aggregate(where: {liked_item: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}

`