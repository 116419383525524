import React, { useState, createContext, useRef } from "react";
import styles from "./Home.module.scss";
import BottomPageFonts from "./components/BottomPageFonts/BottomPageFonts";
import PageManIconPart from "./components/PageManIconPart/PageManIconPart";
import HapePageRight from "./components/HapePageRight/HapePageRight";
import ProductDescription from "./components/ProductDescription/ProductDescription";
import Customize360Environment from "./components/Customize360Environment/Customize360Environment";
import CustomizeTexture from "./components/CustomizeTexture/CustomizeTexture";
import ColorPicker from "./components/ColorPicker/ColorPicker";
import MainNavBar from "./components/MainNavBar/MainNavBar";
import { useParams } from "react-router-dom";
import { SINGLE_GLB_INFO } from "../../graphql/singleGlbInfo.query";
import { useQuery } from "@apollo/client";
import CircularProgressComponent from "../../components/circularProgress/CircularProgress.component";

export const homeContext = createContext();

export default function Home() {
  const [renderCustomize, setRenderCustomize] = useState("rotate3D");
  const [percentage, setPercentage] = useState(0);

  const sideBarRoute = {
    "rotate3D": {
      component: ProductDescription,
      icon: "/images/tag.svg",
      title: "Product description",
    },
    "shirtCustomize": {
      component: CustomizeTexture,
      icon: "/images/img.png",
      title: "Customize texture"
    },
    "addCustomize": {
      component: Customize360Environment,
      icon: "/images/rotateenv.svg",
      title: "Customize 360 environment"
    },
    "colorPalette": {
      component: ColorPicker,
      icon: "/images/brain-cloud.svg",
      title: "Customize Color"
    },
  }

  const uvCanvasRef = useRef();
  const { id } = useParams();
  const { data } = useQuery(SINGLE_GLB_INFO, { variables: { id } });
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [patternInfo, setPatternInfo] = useState(null)
  const [activeIcon, setActiveIcon] = useState(null);
  const [selectedPattern, setSelectedPattern] = useState(null)
  const [editPosition, setEditPosition] = useState(false)

  return (
    <homeContext.Provider value={{ uvCanvasRef, percentage, setPercentage, setLoadingPopup, patternInfo, setPatternInfo, activeIcon, setActiveIcon, selectedPattern, setSelectedPattern, editPosition, setEditPosition }}>
      {loadingPopup && <CircularProgressComponent onTop color="info" />}
      <div id={styles.home}>
        <MainNavBar />
        <div className="pageBody">
          <div className="leftPagePart">
            <PageManIconPart setRenderCustomize={setRenderCustomize} />
          </div>
          <div className="rightPagePart">{data && <HapePageRight title={data.users_glbs_by_pk.name} icon={sideBarRoute[renderCustomize].icon} hapeBodyTopTitle={sideBarRoute[renderCustomize].title} HapeBodyBottomContent={sideBarRoute[renderCustomize].component} />}</div>
        </div>
        <BottomPageFonts />
      </div>
    </homeContext.Provider>
  );
}
