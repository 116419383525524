import React, { useRef, useState } from "react";
import FormUpload from "./FormUpload";
import FormLayout from "../../../../components/dashboardLayout/FormLayout.layout";
import Steps from "../../Steps";


export default function UploadStep(props) {
    const [glbPreview, setGlbPreview] = useState(null);
    const [placeholderPreview, setPlaceholderPreview] = useState(null);

    const btnRef = useRef()
    const handleNext = () => {
        if (btnRef.current.disabled) {
            return
        }
        btnRef.current.click()
    }

    return (
        <>
            <Steps activeStep={0} handleNext={handleNext} />
            <FormLayout
                title="Upload Your Glb"
                subTitle="Submit Your 3D Model: Name, File, Rarity, and SKU"
                glbPreview={glbPreview}
                imagePreview={placeholderPreview}
                imagePreviewTitle="Glb Placeholder Preview"
                {...props}
            >
                <FormUpload
                    ref={btnRef}
                    setGlbPreview={setGlbPreview}
                    setPlaceholderPreview={setPlaceholderPreview}
                />
            </FormLayout>
        </>
    )
}
