import { gql } from "@apollo/client";

export const LIST_OF_GAMES = gql`
  query LIST_OF_GAMES {
    sdk_games {
      created_at
      environment
      id
      name
      notes
      updated_at
      website_url
    }
  }
`;
